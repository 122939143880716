import styled from 'styled-components'
import Link from 'next/link'

import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'

export interface IImageRowCarouselSlice extends IBaseSlice {
	slice_type: 'image_row_carousel_'
	primary: {}
	items: {
		image?: IPrismicImage
		link?: IPrismicLink
	}[]
}

interface IProps {
	sliceData: IImageRowCarouselSlice
}

const GAP = 20

const ImageRowCarouselSlice: React.FC<IProps> = ({ sliceData }) => {
	const { pages } = usePrismicRoutingContext()



	return (
        <Container>
			<SlideTrack numberOfItems={sliceData.items.length}>
				{[...sliceData.items, ...sliceData.items].map((item, index) => {
					return (
                        (<Link href={`${resolvePrismicLink(item.link!, pages)}`} key={index}>

                            <Slide >
                                <StyledPrismicImage src={item.image!.url} />
                            </Slide>

                        </Link>)
                    );
				})}

			</SlideTrack>
		</Container>
    );
}

export default ImageRowCarouselSlice

const Container = styled.div<{ backgroundColor?: string }>`
	padding: var(--spacer-sm) 0;

	position: relative;
	display: grid;
	place-items: center;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.grey800};

	${media.phone`
        padding: var(--spacer-base) 0;
    `}

	&:before,
	&:after {
		position: absolute;
		top: 0;
		width: 400px;
		height: 100%;
		content: '';
		z-index: 1;
	}
	:before {
		left: 0;
		background: linear-gradient(to right, #333 0%, transparent 100%);

		${media.phone`
        	display:none;
    	`}
	}
	:after {
		right: 0;
		background: linear-gradient(to left, #333 0%, transparent 100%);

		${media.phone`
        	display:none;
    	`}
	}
`

const StyledPrismicImage = styled.img`
	object-fit: contain;

	height: 100%;
	width: 100%;
	transition: all 0.15s;

	:hover {
		transform: scale(1.05);
	}
`

const SlideTrack = styled.div<{ numberOfItems: number }>`
	display: flex;
	width: max(200vw + ${(props) => `${2 * props.numberOfItems * GAP + GAP}px`}, ${(props) => `${2 * (props.numberOfItems * 250 + GAP * (props.numberOfItems)) + GAP}px`});
	justify-content: space-around;
	gap: ${`${GAP}px`};
	overflow: hidden;
	position: relative;

	animation: scrolling 30s linear infinite;

	@keyframes scrolling {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(min(-100vw - ${(props) => `${(props.numberOfItems + 1) * GAP}px`}, ${(props) => `-${props.numberOfItems * 250 + GAP * (props.numberOfItems + 1)}px`}));
		}
	}
`

const Slide = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	width: 250px;
	height: 150px;
	perspective: 100px;
`
