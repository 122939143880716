import styled from 'styled-components'

import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid/Grid'
import LinearGradientBackground from '../../Layout/LinearGradientBackground'

export interface IImageRowSlice extends IBaseSlice {
	slice_type: 'imagerow'
	primary: {
		image_style?: 'darkGold' | 'lightGold'
	}
	items: {
		image?: IPrismicImage
	}[]
}

interface IProps {
	sliceData: IImageRowSlice
}

const MAX_NUMBER_OF_IMAGES_IN_ROW = 3

const ImageRowSlice: React.FC<IProps> = ({ sliceData }) => {
	const imageItems = sliceData.items.slice(-`${MAX_NUMBER_OF_IMAGES_IN_ROW}`)

	return (
		<Grid numberOfImages={imageItems.length}>
			{imageItems.map((item, index) => {
				return (
					<StyledBackgroundImage
						key={index}
						backgroundUrl={item.image!.url}
						imageStyle={sliceData.primary.image_style}
					/>
				)
			})}
		</Grid>
	)
}

export default ImageRowSlice

const Grid = styled(FullBleedGrid)<{
	numberOfImages: number
}>`
	grid-template-columns: repeat(${(props) => `${props.numberOfImages}`}, 1fr);
	column-gap: 0;

	${media.desktopMinPlus`
		grid-template-columns: repeat(${(props) =>
			`${props.numberOfImages > 2 ? 2 : props.numberOfImages}`}, 1fr);
	`}

	${media.phone`
		grid-template-columns: 1fr;
	`}
`

const StyledBackgroundImage = styled(LinearGradientBackground)<{
	backgroundUrl?: string
	imageStyle?: 'darkGold' | 'lightGold'
}>`
	height: 320px;
	width: 100%;
	background-size: cover;
	background-position: center;

	${media.desktopMinPlus`
		&:nth-child(n+3) {
	    	display: none;
		}
	`}

	${media.phone`
		&:not(:first-child) {
	    	display: none;
		}
	`}
`
