import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled from 'styled-components'

import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { Arrow } from '../../icons'
import KvikaSymbol from '../../icons/KvikaSymbol'
import TMLogo from '../../icons/TMLogo'
import { FullBleedGrid } from '../../Layout/Grid'
import PrismicLink from '../../Links/PrismicLink'
import PrismicImage from '../../PrismicImage'
import RichText from '../../RichText/RichText'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import OrtusLogo from '../../icons/OrtusLogo'

export interface ISubsidiaryInfoSlice extends IBaseSlice {
	primary: {
		description1: RichTextBlock[]
		image: IPrismicImage
		link: IPrismicLink
		link_text: string
		title1: string
		logo: 'Kvika' | 'TM' | 'KES' | 'Ortus'
	}
	slice_type: 'subsidiaryinfo'
}

interface Props {
	sliceData: ISubsidiaryInfoSlice
}

const SubsidiaryInfo = ({ sliceData }: Props) => {
	return (
		<GridContainer>
			<FullBleedSectionTitle title={sliceData.primary.title1} />
			<LogoContainer>
				<SubsidiaryLogo logo={sliceData.primary.logo} />
			</LogoContainer>
			<ContentContainer>
				<RichText content={sliceData.primary.description1} />
				<PrismicLinkContent link={sliceData.primary.link}>
					<>
						{sliceData.primary.link_text}
						<Arrow height={16} width={18} rotate={180} />
					</>
				</PrismicLinkContent>
			</ContentContainer>
			<ImageContainer>
				<PrismicImage image={sliceData.primary.image} layout={'responsive'} />
			</ImageContainer>
		</GridContainer>
	)
}

interface ISubsidiaryLogoProps {
	logo: 'Kvika' | 'TM' | 'KES' | 'Ortus'
}

const SubsidiaryLogo = ({ logo }: ISubsidiaryLogoProps) => {
	switch (logo) {
		case 'KES':
			return <KvikaSymbol stroke={'#888A8C'} fill={'#888A8C'} />
		case 'TM':
			return <TMLogo />
		case 'Kvika':
			return <KvikaSymbol />
		case 'Ortus' :
			return <OrtusLogo width={104} height={104} />
		default:
			return <></>
	}
}

const GridContainer = styled(FullBleedGrid)`
	padding: 40px 0;
	justify-content: center;
`

const LogoContainer = styled.div`
	grid-column: 2 / span 2;

	${media.phone`
    grid-column: 2 / -2;
`}
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */

	grid-column: 4 / span 4;

	${media.phone`
		grid-column: 2 / -2;
	`}
`

const PrismicLinkContent = styled(PrismicLink)`
	text-decoration: none;
	display: flex;
	align-items: center;
	padding-top: 48px;
`

const ImageContainer = styled.div`
	grid-column: -6 / -2;

	${media.phone`
		grid-column: 2 / -2;
	`}

	gap: 40px;
	/* display: flex; */
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
`

export default SubsidiaryInfo
