import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'

import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'
import HeroCarousel from '../HeroImage/HeroCarousel'


export interface HeroCarouselItem {
	image: IPrismicImage
	position?: 'top' | 'bottom' | 'center'
	text: RichTextBlock[]
	decoration: boolean
}


export interface IHeroCarouselSlice extends IBaseSlice {
	items: HeroCarouselItem[]
	slice_type: 'hero_carousel'
}

interface Props {
	sliceData: IHeroCarouselSlice
}

const HeroCarouselSlice: React.FC<Props> = ({ sliceData }) => {
	return <HeroCarousel items={sliceData.items} />
}

export default HeroCarouselSlice
