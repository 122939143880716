import React from 'react'
import styled from 'styled-components'

import { IBaseSlice } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { Grid } from '../../Layout/Grid'
import SectionTitle from '../../Section/SectionTitle'


export interface IMultiSectionDescription extends IBaseSlice {
	primary: {
		section_title: string
	}
	items: {
		text_description: string
		text_title: string
	}[]
	slice_type: 'multi_section_description'
}

export interface IProps {
	sliceData: IMultiSectionDescription
}

const MultiSectionDescription = ({ sliceData }: IProps) => {
	const singleContent = sliceData.items.length === 1
	return (
		<GridContainer>
			<SectionTitle title={sliceData.primary.section_title} />
			{singleContent ? (
				<TextContainer singleContent={true} column={1}>
					<h4>{sliceData.items[0].text_title}</h4>
					<div>{sliceData.items[0].text_description}</div>
				</TextContainer>
			) : (
				sliceData.items.map((singleItem, index) => {
					return (
						<TextContainer singleContent={false} column={index % 2} key={index}>
							<h4>{singleItem.text_title}</h4>
							<div>{singleItem.text_description}</div>
						</TextContainer>
					)
				})
			)}
		</GridContainer>
	)
}

const GridContainer = styled(Grid)`
	padding: 40px 0;
	row-gap: 40px;
`

const TextContainer = styled.div<{ singleContent: boolean; column: number }>`
	${(props) =>
		props.singleContent
			? 'grid-column: 1 / span 7'
			: props.column === 0
				? 'grid-column: 1 / span 5'
				: 'grid-column: 7 / span 5'};

	${media.tablet`
		${(props) => props.singleContent && 'grid-column: 1 / -1'}
	`}

	${media.phone`
		grid-column: 1 / -1
	`}
`

// ${media.phone`
//     grid-column: 3 / -3;
// `}

// const MultiContentContainer = styled.div`
// 	font-size: 1rem;
// 	display: flex;
// 	flex-direction: column;
// 	/* justify-content: center; */

// 	grid-column: 2 / span 4;

// 	${media.phone`
// 		grid-column: 3 / -3;
// 	`}
// `

export default MultiSectionDescription
