import React, { useState } from 'react'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

import useCurrencyData from '../../hooks/data/use-currency-data'
import { media } from '../../utils/style-utils'
import { nameof } from '../../utils/tsHelpers'
import { FullBleedGrid } from '../Layout/Grid'
import useLocalization from '../../hooks/utils/use-localization'


import CalculatorBody from './CalculatorBody'
import CalculatorTabContainer from './CalculatorTabContainer'

export interface ICurrencyRates {
	exchangeRates: Array<ICurrencyRate>
	noteRates: Array<ICurrencyRate>
	visaRates: Array<ICurrencyRate>
}

export interface ICurrencyRate {
	currencyCode: string // Name
	buyRate: number // Buy
	sellRate: number // Selling
}

const Calculator = () => {
	const { translate } = useLocalization()
	const { isSuccess, isLoading, isError, refetch, data } = useCurrencyData()
	const [currencyType, setCurrencyType] = useState<keyof ICurrencyRates>(
		nameof<ICurrencyRates>('noteRates')
	)

	const changeCurrencyType = (currencyType: keyof ICurrencyRates) => {
		setCurrencyType(currencyType)
	}

	return (
		<FullBleedGrid>
			<StyledCalculatorContainer>
				{isLoading ? (
					<SpinnerContainer>
						<CircularProgress size={64} />
					</SpinnerContainer>
				) : isError ? (
					<SpinnerContainer>
						<h2>{translate('general.loadingError')}</h2>
						<RetryButton onClick={() => refetch()}>
							{translate('general.tryAgain')}
						</RetryButton>
					</SpinnerContainer>
				) : isSuccess ? (
					<>
						<CalculatorTabContainer
							currencyType={currencyType}
							changeCurrencyType={changeCurrencyType}
						/>
						<CalculatorBody currencyType={currencyType} currencyRates={data} />
					</>
				) : (
					<></>
				)}
			</StyledCalculatorContainer>
		</FullBleedGrid>
	)
}

export default Calculator

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
`

const RetryButton = styled.button`
	cursor: pointer;
	font-weight: 400;
	font-size: 1.25rem;
	border-radius: 0;
	border: none;
	color: #fff;
	margin-top: 20px;
	transition: opacity 0.25s ease, box-shadow 0.25s ease;
	background-color: #a28352;
	padding: 10px 20px 11px;
	&:hover {
		box-shadow: rgb(0 0 0 / 15%) 1px 8px 40px;
	}
	&:focus {
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -2px;
	}
`

const StyledCalculatorContainer = styled.div`
	grid-column: 2 / -2;
	display: flex;
	margin: 50px 0px;

	font-family: 'AkzidenzGroteskPro';

	${media.phone`
		flex-direction: column;
	`}
`