import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'


import { Card } from '../Card'

interface Props {
	link: {
		url: string
		card_title: string
	}
}

const LinkCard: React.FC<Props> = ({ link }) => {
	return (
        <Card>
			<Link href={link.url} passHref={true} legacyBehavior>
				<StyledAnchor>{link.card_title}</StyledAnchor>
			</Link>
		</Card>
    );
}

export default LinkCard

// * Exported in order to be extended by consumer
export const StyledAnchor = styled.a`
	min-height: 66px;
	background: #ffffff;
	display: flex;
	align-items: center;
	padding-left: 30px;
	margin-right: auto;
	border-left: 3px solid ${(props) => props.theme.colors.gold600};

	@media (max-width: 750px) {
		min-width: 100%;
	}

	font-family: AdobeCaslonPro, Serif;
	font-size: 1.5rem;
	line-height: 60px;
	font-weight: 700;
	text-decoration: none;
`
