import React from 'react'
import styled, { css } from 'styled-components'

import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'

export interface IHeroImageSlice extends IBaseSlice {
	primary: {
		background: IPrismicImage
		description1?: string
		heading?: string
		hide_decoration: boolean
	}
	slice_label?: string
	slice_type: 'heroimage'
}


interface IProps {
	sliceData: IHeroImageSlice
}

const HeroImage: React.FC<IProps> = ({ sliceData }) => {
	// * The decoration is set by default, but can be toggled on/off via label
	const hideDecoration = sliceData?.primary.hide_decoration

	// * The component will still render if no text is provided
	// * Uncertain if this is a valid use case
	const hasText = sliceData?.primary?.heading || sliceData?.primary?.description1

	if (!sliceData?.primary?.background) {
		// * If no background image is set, do not render
		return null
	}

	return (
		<SCBackground url={sliceData?.primary.background.url}>
			{hasText && (
				<SCCaption>
					{!hideDecoration && <SCDecoration />}
					<SCCaptionHeading>{sliceData?.primary?.heading}</SCCaptionHeading>
					<SCCaptionDescription>{sliceData?.primary?.description1}</SCCaptionDescription>
				</SCCaption>
			)}
		</SCBackground>
	)
}

export default HeroImage

const SCBackground = styled.section<{ url: string }>`
	${({ url }) => css`
		/* height: 100%; */
		width: 100%;
		background: url(${url}) no-repeat;
		background-position: 50%;
		background-position-y: 55%;
		background-size: cover;
		height: 100vh;
		position: relative;
	`}
`
const SCCaption = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 48%;
	padding: 16px 48px 16px 16px;
	position: absolute;
	z-index: 1;
	bottom: 15%;
	top: auto;
	right: auto;
	left: 10%;
	color: white;
`

const SCCaptionHeading = styled.h1`
	font-size: 3.5rem;
	font-weight: 700;
	line-height: 56px;
	letter-spacing: 1px;
	margin: 0;
`
const SCCaptionDescription = styled.span`
	font-size: 1.375rem;
	line-height: 32px;
`

const SCDecoration = styled.div`
	top: -56px;
	left: -56px;
	z-index: -1;
	position: absolute;
	height: 224px;
	width: 224px;
	background: linear-gradient(131.31deg, rgba(186, 148, 94, 0.8) 0%, rgba(142, 113, 72, 0) 100%);
`
