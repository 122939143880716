import React from 'react'
import styled, { css } from 'styled-components'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { map } from 'lodash'

import { IBaseSlice } from '../../../types/prismic/baseTypes'
import { IPage } from '../../../types/prismic/pages/generalPage'
import Accordion from '../../Accordion/Accordion'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import HtmlSerializer from '../../HtmlSerializer/htmlSerializer'
import { FullBleedGrid } from '../../Layout/Grid'
import { media } from '../../../utils/style-utils'
import { StyledRichTextContainer } from '../../RichText/RichText.styles'


export interface RichTextQASlice extends IBaseSlice {
	items: {
		question: string
		answer: RichTextBlock[]
	}[]
	primary: {
		content: RichTextBlock[]
		direction: 'QA+RichText' | 'RichText+QA'
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	slice_type: 'richtext___q_a'
}


interface IProps {
	sliceData: RichTextQASlice
	flip?: boolean
}

const RichTextQA: React.FC<IProps> = ({ sliceData }) => {
	const flip = sliceData.primary.direction === 'QA+RichText'

	return (
		<StyledFullBleedGrid backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			<StyledRichText flip={flip}>
				<RichText
					render={sliceData.primary.content}
					htmlSerializer={HtmlSerializer}
				/>
			</StyledRichText>
			<QuestionsContainer flip={flip}>
				{map(sliceData.items, (question, index) => (
					<Accordion key={`question-${index}`} heading={question.question}>
						<RichText
							render={question.answer}
							htmlSerializer={HtmlSerializer}
						/>
					</Accordion>
				))}
			</QuestionsContainer>
		</StyledFullBleedGrid>
	)
}

export default RichTextQA

const StyledFullBleedGrid = styled(FullBleedGrid) <{ backgroundColor?: string }>`
	padding-top: 80px;
	padding-bottom: 120px;
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`};

	${media.tablet`
			row-gap: var(--spacer-sm);
	`}
`

const StyledRichText = styled(StyledRichTextContainer) <{ flip?: boolean }>`
	grid-column: ${({ flip }) => (flip ? '9 / span 5' : '2 / span 6')};

	grid-row: 2;

	${media.tablet`
		grid-row: ${({ flip }) => (flip ? 3 : 2)};
		grid-column: 2 / -2;
	`}

	img {
		width: 100%;
	}
`
const QuestionsContainer = styled.div<{ flip?: boolean }>`
	grid-column: ${({ flip }) => (flip ? '2 / span 6' : '8 / -2')};

	grid-row: 2;

	${media.tablet`
		grid-row: ${({ flip }) => (flip ? 2 : 3)};
		grid-column: 2 / -2;
	`}
`
