import React from 'react'
import styled from 'styled-components'
import { RichText, RichTextBlock } from 'prismic-reactjs'

import { media } from '../../../utils/style-utils'
import HtmlSerializer from '../../HtmlSerializer/htmlSerializer'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import { IBaseSlice } from '../../../types/prismic/baseTypes'

export interface ITwoColumnList extends IBaseSlice {
	primary: {
		section_title: string
		first_column: RichTextBlock[]
		second_column: RichTextBlock[]
	}
	slice_type: 'two_column_list'
}
interface IProps {
	sliceData: ITwoColumnList
}

const TwoColumnList = ({ sliceData }: IProps) => {
	return (
		<GridContainer>
			<StyledSectionTitle title={sliceData.primary.section_title} />
			<StyledTextContainer>
				<RichText
					render={sliceData.primary.first_column}
					htmlSerializer={HtmlSerializer}
				/>
			</StyledTextContainer>
			<StyledTextContainer>
				<RichText
					render={sliceData.primary.second_column}
					htmlSerializer={HtmlSerializer}
				/>
			</StyledTextContainer>
		</GridContainer>
	)
}

const GridContainer = styled(FullBleedGrid)`
	padding-bottom: var(--spacer-base);

	${media.phone`
		padding-bottom: var(--spacer-md);
	`}
`

const StyledSectionTitle = styled(FullBleedSectionTitle)`
	margin-bottom: var(--spacer-xs);
`

const StyledTextContainer = styled.div`
	grid-column: 2 / span 5;

	&:last-of-type {
		grid-column: 8 / span 5;
	}

	ul {
		margin: 0;
		padding: 0;
		font-weight: 400;
		color: ${(props) => props.theme.colors.grey600};
		list-style-type: none;

		li {
			display: flex;
			padding-bottom: 40px;

			&:last-child {
				padding-bottom: 0;
			}

			svg {
				margin-right: 4px;
				flex-shrink: 0;
			}
		}
	}

	${media.phone`
		grid-column: 2 / -2;
		padding-bottom: 40px;

		&:last-of-type {
			grid-column: 2 / -2;
			padding-bottom: 0;
		}
	`};
`

export default TwoColumnList
