import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import { FullBleedGrid } from '../Layout/Grid'
import RichText from '../RichText/RichText'

export interface INotificationSlice extends IBaseSlice {
	primary: {
		type: string
		text: RichTextBlock[]
	}
	slice_type: 'notification'
}

interface Props {
	sliceData: INotificationSlice
}

const Notification: React.FC<Props> = ({ sliceData }) => {
	return (
		<Container type={sliceData.primary.type}>
			<NotificationGrid>
				<TextWrapper>
					<RichText content={sliceData.primary.text} />
				</TextWrapper>
			</NotificationGrid>
		</Container>
	)
}

const Container = styled(FullBleedGrid) <{ type?: string }>`
	background: ${({ theme }) => theme.colors.gold550};

	${({ type, theme }) =>
		(type === 'warning' &&
			css`
				background: ${theme.colors.warning};
			`) ||
		(type === 'error' &&
			css`
				background: ${theme.colors.error};
			`) ||
		(type === 'success' &&
			css`
				background: ${theme.colors.success};
			`)}
`

const TextWrapper = styled.div`
	grid-column: 1 / -1;

	h2 {
		color: white;
		font-family: AkzidenzGroteskPro, sans-serif;
		font-weight: 500;
	}
`

const NotificationGrid = styled.div`
	grid-column: 2 / -2;
	margin-block: var(--spacer-md);
`

export default Notification
