import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { RichTextBlock } from 'prismic-reactjs'

import { devices, media } from '../../../utils/style-utils'
import { FullBleedGrid } from '../../Layout/Grid/Grid'
import Ruler from '../../Ruler/Ruler'
import LinearGradientBackground from '../../Layout/LinearGradientBackground'
import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import RichText from '../../RichText/RichText'

export interface IHalfImageDescriptionSlice extends IBaseSlice {
	primary: {
		background: IPrismicImage
		heading?: string
		heading_color?: 'default' | 'gold'
		description?: RichTextBlock[] | string
		imageleft?: boolean
		image_style?: 'darkGold' | 'lightGold'
		image_first_on_mobile?: boolean
	}
	items: {
		link_button: IPrismicLink
		button_text: string
	}[]
	slice_label?: string
	slice_type: 'halfimagedescription'
}

const HalfImageDescription: React.FC<IHalfImageDescriptionSlice> = ({
	primary,
	items,
}) => {
	const { pages } = usePrismicRoutingContext()
	const reverse = primary?.imageleft
	const isImageFirst = primary?.image_first_on_mobile
	const isDescriptionRichText = typeof primary?.description === 'object'

	return (
        <Grid>
			<TextContainer reverse={reverse} isImageFirst={isImageFirst}>
				<SCCaptionHeading
					headingColor={primary.heading_color}
					className='primary'
				>
					{primary?.heading}
				</SCCaptionHeading>
				<Ruler />
				{isDescriptionRichText ? (
					<RichTextWrapper>
						<RichText content={primary.description as RichTextBlock[]} />
					</RichTextWrapper>
				) : (
					<SCCaptionDescription className='primary'>
						{primary?.description as string}
					</SCCaptionDescription>
				)}
				<ButtonsContainer>
					{items?.map((item, index) => (
						<Link
                            key={index}
                            href={resolvePrismicLink(item.link_button, pages) ?? '#'}
                            legacyBehavior>
							<SliceButton>{item.button_text}</SliceButton>
						</Link>
					))}
				</ButtonsContainer>
			</TextContainer>
			<ImageWrapper
				imageStyle={primary.image_style}
				isImageFirst={isImageFirst}
				backgroundUrl={primary.background.url}
				reverse={reverse}
			/>
		</Grid>
    );
}

export default HalfImageDescription

const ButtonsContainer = styled.div`
	margin-top: 72px;
	justify-content: space-between;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	button {
		cursor: pointer;
	}

	${media.tablet`
		flex-direction: column;
	`}
`

const SliceButton = styled.button`
	flex-grow: 1;
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.colors.gold550};
	color: ${({ theme }) => theme.colors.gold550};
	border-radius: 2px;
	padding: 16px 32px;

	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1rem;

	&:hover {
		background-color: ${({ theme }) => theme.colors.gold550};
		color: white;
	}
`

const Grid = styled(FullBleedGrid)`
	min-height: 754px;

	${devices.safariDesktop`
		grid-template-rows: minmax(750px, 100%);
		${media.phone`
			grid-template-rows: min-content;
		`};
	`}
	${media.phone`
		min-height: 454px;
	`}
`

const TextContainer = styled.div<{ reverse?: boolean; isImageFirst?: boolean }>`
	padding: 40px 0 32px 0;
	grid-column: ${({ reverse }) => (reverse ? '9 / span 5' : '2 / span 5')};
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.phone`
		grid-column: 3 / span 10;
		grid-row: ${({ isImageFirst }) => (isImageFirst ? 2 : 1)};
		min-height: 280px;
	`}
`

const ImageWrapper = styled(LinearGradientBackground)<{
	backgroundUrl: string
	reverse?: boolean
	isImageFirst?: boolean
}>`
	height: 100%;
	background-size: cover;
	grid-column: ${({ reverse }) => (reverse ? '1 / span 7' : '8 / span 7')};
	grid-row: ${({ reverse }) => (reverse ? '1' : 'auto')};

	margin-right: ${({ reverse }) => (reverse ? '-8px' : '0')};
	margin-left: ${({ reverse }) => (reverse ? '0' : '-8px')};

	${media.phone`
		margin-right: auto;
		margin-left: auto;
		grid-column: 1 / -1;
		grid-row: ${({ isImageFirst }) => (isImageFirst ? 1 : 2)};
		height: 424px;
		width: 100%;
	`}
`

const SCCaptionHeading = styled.h1<{ headingColor?: 'default' | 'gold' }>`
	font-weight: 600;
	font-size: 3.5625rem;
	line-height: 4rem;
	letter-spacing: 1px;
	margin-bottom: var(--spacer-md);

	${media.phone`
		margin-bottom: var(--spacer-xs);
	`};

	color: ${({ theme, headingColor }) =>
		headingColor === 'gold' ? theme.colors.gold550 : 'inherit'};
`
const SCCaptionDescription = styled.span`
	font-size: 1.375rem;
	line-height: 2rem;
	font-family: AdobeCaslonPro, serif;
	font-weight: 400;
	margin-top: var(--spacer-sm);

	${media.phone`
		font-size: 1.125rem;
		margin-top: var(--spacer-xs);
	`}
`

const RichTextWrapper = styled.div`
	* > p {
		font-size: 1.375rem;
		line-height: 2rem;
		font-family: AdobeCaslonPro, serif;
		font-weight: 400;
		margin-top: var(--spacer-sm);
	}

	* > ul {
		font-size: 1.25rem;
		line-height: 2.5rem;
		font-family: AdobeCaslonPro, serif;
		padding-left: 1rem;
		line-height: 1.2;
		margin-top: var(--spacer-sm);

		${media.phone`
			padding-left: 0;
		`}
	}
`
