import * as React from 'react'

import { KvikaSVGProps } from './types'

const Trash = ({
	height = 20,
	fill = 'none',
	width = 20,
	rotate = 0,
	stroke = '#8E7148',
}: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path
			d='M7 0H7.5H12.5H13L13.2812 0.417969L15.0859 3.125H15.6211H17.5H18.75V5H17.3594L16.25 20H3.75L2.64062 5H1.25V3.125H2.5H4.37891H4.91406L6.71875 0.417969L7 0ZM7.17188 3.125H12.8359L12 1.875H8L7.16797 3.125H7.17188ZM4.51953 5L5.49219 18.125H14.5078L15.4805 5H4.51953Z'
			fill={stroke}
		/>
	</svg>
)

export default Trash
