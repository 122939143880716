import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { useSwiper } from 'swiper/react'

import { Slide } from './TextCarouselSlice'

interface IDot {
	items: Slide[]
	setCurrentSlide: React.Dispatch<React.SetStateAction<number>>
	currentSlide: number
}

const Dots: React.FC<IDot> = ({ items, setCurrentSlide, currentSlide }) => {
	const swiper = useSwiper()

	return (
		<DotsContainer>
			{map(items, (_, index) => (
				<Dot
					onClick={() => {
						swiper.slideTo(index)
						return setCurrentSlide(index)
					}}
					key={`dot-${index}`}
					isActive={currentSlide === index}
					id={`${'swiper-forward'}`}
				/>
			))}
		</DotsContainer>
	)
}

export default Dots

const DotsContainer = styled.div`
	margin-left: 4px;
	display: flex;
	gap: 8px;
`

const Dot = styled.div<{ isActive: boolean }>`
	border: 4px solid
		${({ isActive, theme }) =>
			isActive ? theme.colors.gold550 : theme.colors.grey200};
	border-radius: 50%;
	max-height: 8px;

	z-index: 10000;

	:hover {
		cursor: pointer;
	}
`
