import Image from 'next/image'
import styled from 'styled-components'

import {
	IPrismicLink,
	IPrismicWebLink,
	IBaseSlice,
} from '../../../types/prismic/baseTypes'
import { FullBleedGrid } from '../../Layout/Grid'

export interface IExternalImageSlice extends IBaseSlice {
	primary: {
		link: IPrismicLink
		width:
			| '12'
			| '11'
			| '10'
			| '9'
			| '8'
			| '7'
			| '6'
			| '5'
			| '4'
			| '3'
			| '2'
			| '1'
	}
	slice_type: 'online_image'
}

interface IProps {
	sliceData: IExternalImageSlice
}

const ExternalImageSlice: React.FC<IProps> = ({ sliceData }) => {
	if (sliceData.primary.link.link_type !== 'Web') {
		console.log('OnlineImage slice being used for non-web link type')
		return null
	}

	const webLink = sliceData.primary.link as IPrismicWebLink
	return (
		<FullBleedGrid>
			<ImageContainer colSpan={sliceData.primary.width}>
				<Img src={webLink.url} alt='' />
			</ImageContainer>
		</FullBleedGrid>
	)
}
const Img = styled.img`
	object-fit: contain;
	width: 100%;
	height: 100%;
`

const ImageContainer = styled.div<{ colSpan }>`
	grid-column: 2 / span ${(p) => p.colSpan};
	position: relative;
`

export default ExternalImageSlice
