import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import useLocalization from '../../../hooks/utils/use-localization'
import { IBaseSlice, IPrismicLink } from '../../../types/prismic/baseTypes'
import { FullBleedGrid } from '../../Layout/Grid'
import { media } from '../../../utils/style-utils'
import { Arrow } from '../../icons'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'

export interface IBackLink extends IBaseSlice {
	slice_type: 'backlink'
	primary: {
		background_color?: 'Gold50' | 'Gold100' | 'Grey50' | 'Grey800'
		link: IPrismicLink
	}
}

interface IProps {
	sliceData: IBackLink
}

const BackLink: React.FC<IProps> = ({ sliceData }) => {
	const { translate } = useLocalization()
	const { pages } = usePrismicRoutingContext()
	const link = resolvePrismicLink(sliceData.primary.link, pages)

	return (
		<GridContainer backgroundColor={sliceData.primary.background_color}>
			<StyledBackLink href={link ?? '#'}>
				<Arrow />
				<StyledButtonText>{translate('general.back')}</StyledButtonText>
			</StyledBackLink>
		</GridContainer>
	)
}

const GridContainer = styled(FullBleedGrid)`
	padding: 40px 0;
	justify-content: center;
`

const StyledButtonText = styled.span`
	margin-left: 10px;
	font-weight: 700;
`

const StyledBackLink = styled(Link)`
	grid-column: 2 / 12;
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
	color: ${(props) => props.theme.colors.gold400};

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	${media.phone`
	grid-column: 2 / 12;
	margin-top: var(--spacer-sm);
	`}
`

export default BackLink
