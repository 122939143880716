import * as React from 'react'

import { KvikaSVGProps } from '../icons/types'

const Ruler = ({
	height = '2px',
	width = '80px',
	fill = '#DED2BE',
	className
}: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
	>
		<path d='M0 2V0h79.823l-2 2H0Z' fill={fill} />
	</svg>
)

export default Ruler
