import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'

import useLocalization from '../../../hooks/utils/use-localization'
import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'
import { devices, media } from '../../../utils/style-utils'
import { Arrow } from '../../icons'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedStyles, HalfGridStyles } from '../../Layout/Grid/Grid'

export interface IHeroRibbonSlice extends IBaseSlice {
	primary: {
		background: IPrismicImage
		description?: string
		heading?: string
		hide_decoration: boolean
		see_more_link: {
			url?: string
		}
		see_more_text?: string
		see_more_arrow?: boolean
	}
	slice_type: 'heroimageribbon'
}

interface IProps {
	sliceData: IHeroRibbonSlice
}

const HeroImageRibbon: React.FC<IProps> = ({ sliceData }) => {
	const { translate } = useLocalization()
	const { url } = sliceData.primary?.see_more_link
	const showArrow = sliceData.primary?.see_more_arrow
	const seeMoreText = sliceData.primary?.see_more_text

	return (
		<Background>
			<BackgroundImage
				src={sliceData?.primary.background.url}
				layout='fill'
				role='presentation'
				alt=''
			/>
			<CardContainer>
				<CaptionContainer>
					<CaptionHeading className='primary'>
						{sliceData?.primary?.heading}
					</CaptionHeading>
					<SCCaptionDescription>
						{sliceData?.primary?.description}
					</SCCaptionDescription>
					{url && (
						<SeeMoreButton href={url}>
							{seeMoreText || translate('general.seeMore')}
							{showArrow && (
								<Arrow
									height={16}
									width={24}
									rotate={180}
									stroke='currentColor'
								/>
							)}
						</SeeMoreButton>
					)}
				</CaptionContainer>
			</CardContainer>

			<TabletBackground>
				<TabletImage
					src={sliceData?.primary.background.url}
					layout='fill'
					objectFit='contain'
					role='presentation'
					alt=''
				/>
			</TabletBackground>
		</Background>
	)
}

export default HeroImageRibbon

const Background = styled(FullBleedGrid)`
	background-position: 50%;
	background-position-y: 55%;
	background-size: cover;
	min-height: 750px;
	position: relative;

	${devices.safariDesktop`
			grid-template-rows: minmax(750px, 100%);
			${media.phone`
				grid-template-rows: min-content;
			`}
	`};

	${media.tablet`
		min-height: auto;
		background: none;
	`}
`

const BackgroundImage = styled(Image)`
	z-index: -1;

	${media.tablet`
	
	transform: translateY(150px);
	`}
`

const CardContainer = styled.div`
	height: 100%;
	font-family: AdobeCaslonPro, Serif;
	background: ${({ theme }) => theme.colors.grey800};
	color: white;

	${HalfGridStyles}

	${media.tablet`
		${FullBleedStyles}
		padding-top: var(--spacer-base);
		padding-bottom: var(--spacer-base);
		grid-column: 1 / -1;
	`}
`

const CaptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 40px;

	grid-column: 2 / -2;

	${media.tablet`
		gap: 10px;
		grid-column: 3 / -3;
	`}
`

const CaptionHeading = styled.h3`
	margin: 0;
	color: ${(props) => props.theme.colors.grey100};
`

const SCCaptionDescription = styled.p`
	margin: 0;
	font-family: AdobeCaslonPro;
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 2.5rem;
	color: ${(props) => props.theme.colors.grey100};
	margin-bottom: var(--spacer-xs);

	${media.phone`
		font-size: 1.125rem;
		line-height: 2rem;
	`}
`

const TabletBackground = styled.div`
	display: none;
	height: 400px;
	position: relative;

	${media.tablet`
		width: 100%;
		z-index: -1;
		display: block;
		height:400px;
	`};

	${media.phone`
		height: 300px;
	`};

	@media only screen and (max-width: 450px) {
		height: 200px;
	}
`

const TabletImage = styled(Image)`
	${media.tablet`
		z-index: 1;
	`};
`

const SeeMoreButton = styled(Link)`
	display: flex;
	align-items: center;
	font-family: AkzidenzGroteskPro;
	font-weight: 700;
	line-height: 25px;
	color: ${(props) => props.theme.colors.gold200};
	gap: 10px;
	text-decoration: none;
	cursor: pointer;
`
