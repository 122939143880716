import Image from 'next/image'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import useLocalization from '../../hooks/utils/use-localization'
import { getCountrySvgFromString } from '../../utils/imageIconEnum'

import { ICurrencyRates } from './Calculator'
import CalculatorInput from './CalculatorInput'

interface ICalculatorBodyProps {
	currencyType: keyof ICurrencyRates
	currencyRates?: ICurrencyRates
}

const CalculatorBody = ({
	currencyType,
	currencyRates,
}: ICalculatorBodyProps) => {
	const { translate } = useLocalization()
	const [iskCurrency, setISKCurrency] = useState(1000)
	const changeCurrencyValue = (newIskValue: number) => {
		setISKCurrency(newIskValue)
	}

	return (
		<StyledGridContainer>
			<StyledGridHeaderRow>
				<div>{translate('calculator.currency')}</div>
				<div>{translate('calculator.buy')}</div>
				<div>{translate('calculator.sell')}</div>
				<div>{translate('calculator.currencyConverter')}</div>
			</StyledGridHeaderRow>
			<StyledGridRowContainer>
				<StyledGridFlagRow>
					<Image
						src={getCountrySvgFromString('ISK')}
						height={16}
						width={24}
						alt={'ISK'}
						layout='fixed'
					/>
					ISK
				</StyledGridFlagRow>
				<div>1,00</div>
				<div>1,00</div>
				<div>
					<CalculatorInput
						iskValue={iskCurrency}
						saleValue={1}
						onInputChange={changeCurrencyValue}
						allowDecimals={false}
					/>
				</div>
			</StyledGridRowContainer>
			{currencyRates &&
				currencyRates[currencyType].map((x, index) => {
					const buyNumberMasked = x.buyRate.toFixed(2).replace('.', ',')
					const saleNumberMasked = x.sellRate.toFixed(2).replace('.', ',')
					return (
						<StyledGridRowContainer key={index}>
							<StyledGridFlagRow>
								<Image
									src={getCountrySvgFromString(x.currencyCode)}
									height={16}
									width={24}
									alt={x.currencyCode}
									layout='fixed'
								/>
								{x.currencyCode}
							</StyledGridFlagRow>
							<div>{buyNumberMasked}</div>
							<div>{saleNumberMasked}</div>
							<div>
								<CalculatorInput
									iskValue={iskCurrency}
									saleValue={x.sellRate}
									onInputChange={changeCurrencyValue}
									allowDecimals={x.currencyCode != 'JPY'}
								/>
							</div>
						</StyledGridRowContainer>
					)
				})}
				<StyledGridRowFooter>
					{currencyType === 'exchangeRates' && (
						<p>{translate('calculator.generalExchangeRateInformation')}</p>
					)}
					{currencyType === 'noteRates' && (
						<p dangerouslySetInnerHTML={{ __html: translate('calculator.banknoteExchangeRateInformationHTML')}} />
					)}
					{currencyType === 'visaRates' && (
						<p dangerouslySetInnerHTML={{ __html: translate('calculator.visaInformationHTML')}} />
					)}
					<p>{translate('calculator.generalExchangeRateDisclaimer')}</p>
				</StyledGridRowFooter>
		</StyledGridContainer>
	)
}

export default CalculatorBody

const StyledGridContainer = styled.div``

const StyledGridRowContainer = styled.div`
	${({ theme: { colors } }) => css`
		display: grid;
		grid-template-columns: 1fr 2.2fr 2.2fr 2.2fr;
		font-weight: 400;
		font-size: 1rem;
		padding: 5px 8px;
		background: ${colors.grey50};
		align-items: center;
		div {
			display: flex;
			justify-content: right;

			&:first-child {
				justify-content: left;
			}
		}

		&:nth-child(2n) {
			background: ${colors.white};
		}
	`}
`
const StyledGridRowFooter = styled.div`
	width: 100%;

	p {
		text-align: left;
		margin: 0 auto;
		margin-top: 2em;
	}
`;

const StyledGridFlagRow = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	font-weight: 500;
`

const StyledGridHeaderRow = styled.div`
	${({ theme: { colors } }) => css`
		display: grid;
		grid-template-columns: 1fr 2.2fr 2.2fr 2.2fr;
		border-bottom: 2px solid ${colors.gold200};
		padding: 4px 8px;
		font-size: 1rem;
		font-weight: 500;
		height: 40px;
		color: ${colors.grey800};
		div {
			display: flex;
			justify-content: right;

			&:first-child {
				justify-content: left;
			}
		}
	`}
`
