import Link from 'next/link'
import React from 'react'
import styled, { css } from 'styled-components'

import { routingPaths } from '../../../constants/Constants'
import useLocalization from '../../../hooks/utils/use-localization'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { IPrismicLink } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import { Arrow } from '../../icons'
import { Grid } from '../../Layout/Grid'
import PrismicLink from '../../Links/PrismicLink'
import SectionTitle from '../../Section/SectionTitle'

import NewsSummarySingle from './NewsSummarySingle'

export interface INewsArticles {
	primary: {
		see_all_news_link: IPrismicLink
		see_all_news_text?: string
		number_of_articles: number
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	newsArticlesPreview: {
		title: string
		short_description: string
		published_date: Date
		href: string
	}[]
	slice_label?: string
	slice_type: 'latest_news_articles'
}

interface IProps {
	sliceData: INewsArticles
}

const NewsSummaryList = ({ sliceData }: IProps) => {
	const { translate, locale } = useLocalization()
	const seeAllNewsText = sliceData.primary?.see_all_news_text
	return (
		<GridContainer backgroundColor={sliceData.primary.section_color}>
			<SectionTitle title={sliceData.primary.section_title} />
			{sliceData.newsArticlesPreview.map((article, index) => {
				return (
					<StyledNewsSummarySingle
						index={index}
						key={article.title}
						publisedDate={new Date(article.published_date)}
						title={article.title}
						description={article.short_description}
						url={article.href}
					/>
				)
			})}
			{sliceData.primary?.see_all_news_link &&
			sliceData.primary?.see_all_news_link.id ? (
				<SCSeeMorePrismic link={sliceData.primary?.see_all_news_link}>
					{seeAllNewsText || translate('slices.seeNews')}
					<Arrow height={16} width={24} rotate={180} />
				</SCSeeMorePrismic>
			) : (
				<SCSeeMore href={routingPaths[locale].news}>
					{seeAllNewsText || translate('slices.seeNews')}
					<Arrow height={16} width={24} rotate={180} />
				</SCSeeMore>
			)}
		</GridContainer>
	)
}

const GridContainer = styled(Grid)<{ backgroundColor?: string }>`
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`}
	padding: 80px 0;
	row-gap: var(--spacer-lg);
`

const StyledNewsSummarySingle = styled(NewsSummarySingle)<{ index: number }>`
	grid-column: ${(p) => (p.index * 4 + 1) % 12} / span 4;
	padding-right: var(--spacer-md);

	${media.tablet`
		grid-column: 2 / -2;
		padding-right: 0;
	`}
`

const SCSeeMore = styled(Link)`
	grid-column: 1 / -1;
	display: flex;
	margin-top: 20px;
	align-items: center;
	font-family: Roboto;
	font-weight: 700;
	text-decoration: none;
	color: ${(props) => props.theme.colors.gold600};
	gap: 10px;

	svg {
		margin-top: 1px;
	}

	${media.tablet`
		grid-column: 2 / -2;
	`}
`

const SCSeeMorePrismic = styled(PrismicLink)`
	grid-column: 1 / -1;
	display: flex;
	margin-top: 20px;
	align-items: center;
	font-family: Roboto;
	font-weight: 700;
	text-decoration: none;
	color: ${(props) => props.theme.colors.gold600};
	gap: 10px;

	svg {
		margin-top: 1px;
	}

	${media.tablet`
		grid-column: 2 / -2;
	`}
`

export default NewsSummaryList
