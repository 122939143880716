import australiaSvg from '../../public/svg/flags/au.svg'
import canadaSvg from '../../public/svg/flags/ca.svg'
import switzerlandSvg from '../../public/svg/flags/ch.svg'
import chinaSvg from '../../public/svg/flags/cn.svg'
import denmarkSvg from '../../public/svg/flags/dk.svg'
import europeSvg from '../../public/svg/flags/eu.svg'
import greatBritainSvg from '../../public/svg/flags/gb.svg'
import icelandSvg from '../../public/svg/flags/is.svg'
import japanSvg from '../../public/svg/flags/jp.svg'
import norwaySvg from '../../public/svg/flags/no.svg'
import polandSvg from '../../public/svg/flags/pl.svg'
import russiaSvg from '../../public/svg/flags/ru.svg'
import swedenSvg from '../../public/svg/flags/se.svg'
import usaSvg from '../../public/svg/flags/us.svg'
import xdrSvg from '../../public/svg/flags/xx.svg'

export function getCountrySvgFromString(currency: string) {
	switch (currency) {
		case 'ISK':
			return icelandSvg
		case 'USD':
			return usaSvg
		case 'GBP':
			return greatBritainSvg
		case 'CAD':
			return canadaSvg
		case 'DKK':
			return denmarkSvg
		case 'NOK':
			return norwaySvg
		case 'RUB':
			return russiaSvg
		case 'SEK':
			return swedenSvg
		case 'CHF':
			return switzerlandSvg
		case 'JPY':
			return japanSvg
		case 'EUR':
			return europeSvg
		case 'AUD':
			return australiaSvg
		case 'CNY':
			return chinaSvg
		case 'PLN':
			return polandSvg
		case 'XDR':
		default:
			return xdrSvg
	}
}
