import styled from 'styled-components'

import EmbeddedYouTubeGrid from '../EmbeddedYouTubeGrid/EmbeddedYouTubeGrid'
import MediaCard from '../MediaCard/MediaCard'
import CalculatorSlice from '../Slices/Calculator/CalculatorSlice'
import ColumnContent from '../Slices/ColumnContent/ColumnContent'
import HalfImageDescription from '../Slices/HalfImageDescription/HalfImageDescription'
import HeroCarouselSlice from '../Slices/HeroCarouselSlice'
import HeroImage from '../Slices/HeroImage/HeroImage'
import HeroImageRibbon from '../Slices/HeroImageRibbon/HeroImageRibbon'
import HeroImageSlice from '../Slices/HeroImageSlice'
import HeroLinkListSlice from '../Slices/HeroLinkList/HeroLinkListSlice'
import { HeroQuote } from '../Slices/HeroQuote'
import HorizontalRuleSlice from '../Slices/HorizontalRuleSlice/HorizontalRuleSlice'
import ImageSlice from '../Slices/ImageSlice'
import InlineImageSlice from '../Slices/InlineImageSlice/InlineImageSlice'
import { LinkCardList } from '../Slices/LinkCardList'
import NewsSummaryList from '../Slices/NewsSummaryList/NewsSummaryList'
import RichTextSlice from '../Slices/RichText/RichText'
import RichTextCards from '../Slices/RichTextCards/RichTextCards'
import RichTextQA from '../Slices/RichTextQA/RichTextQA'
import Showcasing from '../Slices/Showcasing/Showcasing'
import SmallLinkCardListSlice from '../Slices/SmallLinkCardList'
import HtmlTable from '../Table/HtmlTable'
import Notification from '../Notification/Notification'
import OrganizationOperations from '../Slices/OrganizationOperations/OrganizationOperations'
import LinkListDescriptionColumns from '../Slices/LinkListDescriptionColumns/LinkListDescriptionColumns'
import MultiSectionDescription from '../Slices/MultiSectionDescription/MultiSectionDescription'
import Highlight from '../Slices/Highlight/Highlight'
import SubsidiaryInfo from '../Slices/SubsidiaryInfo/SubsidiaryInfo'
import { RichTextTableSliceWrapper } from '../Slices/RichTextTableSliceWrapper'
import SliceShowcaseTitle from '../Slices/SliceShowcaseTitle'
import SingleRowTitleDescription from '../Slices/SingleRowTitleDescription/SingleRowTitleDescription'
import TwoColumnList from '../Slices/TwoColumnList/TwoColumnList'
import { IPageSlice } from '../../types/prismic/slices'
import SpacerSlice from '../Slices/SpacerSlice'
import LinkListSlice from '../Slices/LinkList/LinkListSlice'
import BackLink from '../Slices/BackLink/BackLink'
import EmbeddedVideoSlice from '../Slices/EmbeddedVideo/EmbeddedVideoSlice'
import ImageRowCarouselSlice from '../Slices/ImageRowCarousel/ImageRowCarouselSlice'
import TextCarouselSlice from '../Slices/TextCarousel/TextCarouselSlice'
import ImageRowSlice from '../Slices/ImageRow/ImageRowSlice'
import CardGridSlice from '../Slices/CardGridSlice/CardGridSlice'
import ExternalImageSlice from '../Slices/ExternalImageSlice/ExternalImageSlice'
import GrantApplication from '../Slices/GrantApplication/GrantApplication'

interface ISliceZone {
	slices?: IPageSlice[]
}

// This SliceZone is for general page content and every slice rendered here should use the Grid layout or otherwise assume it will be given a width of 100%

export const PageSliceZone: React.FC<ISliceZone> = ({ slices }) => {
	return (
		<>
			{slices?.map((slice, index) => {
				let component
				switch (slice.slice_type) {
					case 'heroimage':
						component = <HeroImage sliceData={slice} />
						break
					case 'hero_image_with_text':
						component = <HeroImageSlice sliceData={slice} />
						break
					case 'heroquote':
						component = <HeroQuote sliceData={slice} />
						break
					case 'richtext':
						component = <RichTextSlice sliceData={slice} />
						break
					case 'shaded_richtext':
						component = <RichTextSlice shaded sliceData={slice} />
						break
					case 'richtext_cards':
						component = <RichTextCards sliceData={slice} />
						break
					case 'link_card':
						component = <SmallLinkCardListSlice sliceData={slice} />
						break
					case 'hero_carousel':
						component = <HeroCarouselSlice sliceData={slice} />
						break
					case 'link_card1':
						component = <LinkCardList sliceData={slice} />
						break
					case 'media_card':
						component = <MediaCard {...slice} />
						break
					case 'image':
						component = <ImageSlice sliceData={slice} />
						break
					case 'inline_images':
						component = <InlineImageSlice sliceData={slice} />
						break
					case 'preformatted_html_table':
						component = <HtmlTable sliceData={slice} />
						break
					case 'four_col_table_test':
						component = <RichTextTableSliceWrapper sliceData={slice} />
						break
					case 'table__up_to_7_col_':
						component = <RichTextTableSliceWrapper sliceData={slice} />
						break
					case 'hero_link_list':
						component = <HeroLinkListSlice sliceData={slice} />
						break
					case 'column_content':
						component = <ColumnContent sliceData={slice} />
						break
					case 'richtext___q_a':
						component = <RichTextQA sliceData={slice} />
						break
					case 'embedded_grid':
						component = <EmbeddedYouTubeGrid sliceData={slice} />
						break
					case 'calculator':
						component = <CalculatorSlice />
						break
					case 'horizontal_rule':
						component = <HorizontalRuleSlice />
						break
					case 'halfimagedescription':
						component = <HalfImageDescription {...slice} />
						break
					case 'heroimageribbon':
						component = <HeroImageRibbon sliceData={slice} />
						break
					case 'latest_news_articles':
						component = <NewsSummaryList sliceData={slice} />
						break
					case 'showcasing':
						component = <Showcasing sliceData={slice} />
						break
					case 'notification':
						component = <Notification sliceData={slice} />
						break
					case 'organizationoperations':
						component = <OrganizationOperations sliceData={slice} />
						break
					case 'link_list_description_columns':
						component = <LinkListDescriptionColumns sliceData={slice} />
						break
					case 'multi_section_description':
						component = <MultiSectionDescription sliceData={slice} />
						break
					case 'highlight':
						component = <Highlight sliceData={slice} />
						break
					case 'subsidiaryinfo':
						component = <SubsidiaryInfo sliceData={slice} />
						break
					case 'slice_showcase_title':
						component = <SliceShowcaseTitle sliceData={slice} />
						break
					case 'single_row_title_and_description':
						component = <SingleRowTitleDescription sliceData={slice} />
						break
					case 'two_column_list':
						component = <TwoColumnList sliceData={slice} />
						break
					case 'spacer':
						component = <SpacerSlice sliceData={slice} />
						break
					case 'backlink':
						component = <BackLink sliceData={slice} />
						break
					case 'link_list':
						component = <LinkListSlice sliceData={slice} />
						break
					case 'embedded_live_video':
						component = <EmbeddedVideoSlice sliceData={slice} />
						break
					case 'image_row_carousel_':
						component = <ImageRowCarouselSlice sliceData={slice} />
						break
					case 'text_carousel':
						component = <TextCarouselSlice sliceData={slice} />
						break
					case 'imagerow':
						component = <ImageRowSlice sliceData={slice} />
						break
					case 'card_grid':
						component = <CardGridSlice sliceData={slice} />
						break
					case 'online_image':
						component = <ExternalImageSlice sliceData={slice} />
						break
					case 'grant_application':
						component = <GrantApplication sliceData={slice} />
						break
					default:
						if (process.env.VERCEL_ENV !== 'production') {
							console.log(
								'Slice has been deprecated or yet to be implemented: ',
								// @ts-ignore
								slice.slice_type
							)
						}
						return
				}
				return (
					<SliceWrapper
						id={`${slice.slice_type}-${index}`}
						key={`${slice.slice_type}-${index}`}
					>
						{component}
					</SliceWrapper>
				)
			})}
		</>
	)
}

const SliceWrapper = styled.div`
	display: grid;
`

export default PageSliceZone
