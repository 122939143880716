import dayjs from 'dayjs'
import Link from 'next/link'
import * as React from 'react'
import styled from 'styled-components'

import { media } from '../../../utils/style-utils'

interface IProps {
	className?: string
	publisedDate: Date
	title: string
	description?: string
	url: string
}

export default function NewsSummarySingle({
	className,
	publisedDate,
	title,
	description,
	url,
}: IProps) {
	return (
        <NewsCard className={className}>
			<StyledDate>{dayjs(publisedDate).format('DD. MMMM YYYY')}</StyledDate>
			<Link href={url} passHref>

                <StyledTitle>{title}</StyledTitle>

            </Link>
			{description && <StyledDescription>{description}</StyledDescription>}
		</NewsCard>
    );
}

const StyledDescription = styled.p`
	margin: 0;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: ${(props) => props.theme.colors.grey600};
	overflow-wrap: break-word;

	${media.phone`
		display: none;
	`}
`

const StyledTitle = styled.h1`
	margin: 8px 0 16px;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	color: ${(props) => props.theme.colors.grey800};

	${media.phone`
		margin-bottom: 0;
		font-size: 1.125rem;;
	`}
`

const StyledDate = styled.h2`
	margin-top: 0;
	font-size: .8125rem;
	line-height: 24px;
	color: ${(props) => props.theme.colors.gold600};

	${media.phone`
		line-height: 16px;
	`}
`

const NewsCard = styled.div`
	display: inline-block;

	a {
		text-decoration: none;
	}
`
