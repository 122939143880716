export const fileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(removeDataUrl(reader.result))
		reader.onerror = (error) => reject(error)
	})

// Remove dataUrl from base64
export const removeDataUrl = (base64) =>
	base64.split(';')[1].replace('base64,', '')
