import styled from 'styled-components'

import Calculator from '../../Calculator/Calculator'

const CalculatorSlice = () => {
	return <StyledContainer>{<Calculator />}</StyledContainer>
}

export default CalculatorSlice

const StyledContainer = styled.div``
