import React from 'react'
import styled from 'styled-components'

import PrismicImage from '../../PrismicImage'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import { media } from '../../../utils/style-utils'
import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'



export interface IOrganizationOperationsSlice extends IBaseSlice {
	primary: {
		title1: string
		description1: string
		image: IPrismicImage
	}
	slice_type: 'organizationoperations'
}


interface Props {
	sliceData: IOrganizationOperationsSlice
}

const OrganizationOperations = ({ sliceData }: Props) => {
	return (
		<GridContainer>
			<FullBleedSectionTitle title={sliceData.primary.title1} />
			<ContentContainer>
				<div>{sliceData.primary.description1}</div>
			</ContentContainer>
			<StyledImageContainer>
				<PrismicImage image={sliceData.primary.image} layout={'responsive'} />
			</StyledImageContainer>
		</GridContainer>
	)
}

const GridContainer = styled(FullBleedGrid)`
	padding: 40px 0;
`
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */

	grid-column: 2 / span 7;

	${media.phone`
		grid-column: 3 / -3;
	`}
`

const StyledImageContainer = styled.div`
	grid-column: 2 / -2;

	${media.phone`
		grid-column: 2 / -2;
	`}

	gap: 40px;
	/* display: flex; */
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	margin: 100px 0 0 0;
`

export default OrganizationOperations
