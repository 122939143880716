import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { BarSpinner } from '@kvika/spinner'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import Button from '@kvika/button'
import { RichTextBlock } from 'prismic-reactjs'
import { useRouter } from 'next/router'
import { set } from 'lodash'
import * as kennitala from 'kennitala'
import { sanitize } from 'isomorphic-dompurify'

import { IBaseSlice } from '../../../types/prismic/baseTypes'
import useLocalization from '../../../hooks/utils/use-localization'
import { media } from '../../../utils/style-utils'
import InputSelect from '../../Form/InputSelect/InputSelect'
import FormikInputText from '../../FormikForm/FormikInputText/FormikInputText'
import { createAlert } from '../../../providers/notifications/NotificationsProvider'
import { FullBleedGrid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import InputFile, { changeFileFormat } from '../../Form/InputFile/InputFile'
import RichText from '../../RichText/RichText'
import {
	convertRandomMathExpressionToString,
	generateRandomMathExpression,
	calculateCorrectAnswer,
} from '../../../utils/recaptcha-helpers'
import { validFiles, sanitizeFilenames } from '../../../utils/files-helpers'

export interface IGrantApplicationSlice extends IBaseSlice {
	primary: {
		form_description?: RichTextBlock[]
		documents_description?: RichTextBlock[]
	}
	slice_type: 'grant_application'
}

interface IProps {
	sliceData: IGrantApplicationSlice
}

const GRANT_APPLICATION_FLOW_URL = process.env.GRANT_APPLICATION_FLOW_URL || ''
const GRANT_APPLICATION_FLOW_SECRET =
	process.env.GRANT_APPLICATION_FLOW_SECRET || ''

const mimeTypesAndExtensions = {
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',
}

// A function that makes a http post request to submit the form
const sendForm = async (values: {
	fullName: string
	personalId: string
	streetAddress: string
	city: string
	postalCode: string
	email: string
	age: number
	gender: string
	typeOfEducation: string
	educationName: string
	educationStatus: string
	recaptcha: number
	files: File[]
	randomMathExpression: string
	phone: number
}) => {
	let body = {
		...values,
	}

	if (values.files.length === 0) return 500

	const filesArr = await changeFileFormat(values.files)
	set(body, 'files', sanitizeFilenames(filesArr))

	if (!validFiles(body.files, mimeTypesAndExtensions)) {
		return 500
	}

	try {
		const correctAnswer = calculateCorrectAnswer(body.randomMathExpression)
		const recaptchaCheck = body.recaptcha.toString() === correctAnswer

		if (!recaptchaCheck) {
			return 400
		}
	} catch (error) {
		return 500
	}


	try {
		const resp = await fetch(GRANT_APPLICATION_FLOW_URL, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				secret: GRANT_APPLICATION_FLOW_SECRET,
			},
			method: 'POST',
			body: sanitize(JSON.stringify(body)),
		})
		return resp.status
	} catch (error) {
		return 500
	}
}

const GrantApplication: React.FC<IProps> = ({ sliceData }) => {
	const formikRef = useRef<FormikProps<any>>(null)
	const [fileState, setFileState] = useState<File[]>([])
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const { translate } = useLocalization()
	const router = useRouter()

	const GENDER_OPTIONS = [
		{
			value: 'Karl',
			label: translate('grantApplicationForm.genderOptions.man'),
		},
		{
			value: 'Kona',
			label: translate('grantApplicationForm.genderOptions.woman'),
		},
		{
			value: 'Annað',
			label: translate('grantApplicationForm.genderOptions.other'),
		},
	]

	const EDUCATION_OPTIONS = [
		{
			value: 'Iðnnám',
			label: translate('grantApplicationForm.educationOptions.apprenticeship'),
		},
		{
			value: 'Kennaranám',
			label: translate('grantApplicationForm.educationOptions.teacherTraining'),
		},
	]

	const EDUCATION_STATUS_OPTIONS = [
		{
			value: 'Er að hefja nám',
			label: translate('grantApplicationForm.educationStatusOptions.start'),
		},
		{
			value: 'Hef lokið einu ári',
			label: translate(
				'grantApplicationForm.educationStatusOptions.oneYearCompleted'
			),
		},
		{
			value: 'Hef lokið tveimur árum',
			label: translate(
				'grantApplicationForm.educationStatusOptions.twoYearsCompleted'
			),
		},
		{
			value: 'Hef lokið þremur eða fleiri árum',
			label: translate(
				'grantApplicationForm.educationStatusOptions.threeOrMoreYearsCompleted'
			),
		},
	]

	const [randomMathExpression, setRandomMathExpression] = useState<string>()

	useEffect(() => {
		setRandomMathExpression(generateRandomMathExpression())
	}, [])

	return (
		<StyledFullBleedGrid hasBackground={false}>
			<FullBleedSectionTitle
				title={translate('grantApplicationForm.sections.message')}
			/>
			<StyledHalfContent>
				<StyledFormInfo>
					{sliceData.primary.form_description && (
						<RichText content={sliceData.primary.form_description} />
					)}
				</StyledFormInfo>
			</StyledHalfContent>

			<StyledHalfContent>
				<StyledTitle>
					{translate('grantApplicationForm.sections.formTitle')}
				</StyledTitle>
				<Formik
					innerRef={formikRef}
					initialValues={{
						fullName: '',
						personalId: '',
						streetAddress: '',
						city: '',
						postalCode: '',
						email: '',
						phone: '',
						age: '',
						gender: undefined,
						typeOfEducation: undefined,
						educationName: '',
						educationStatus: undefined,
						recaptcha: '',
						files: [],
					}}
					onSubmit={(values, formikBag) => {
						setIsSubmitting(true)
						sendForm({
							...values,
							files: fileState,
							randomMathExpression: randomMathExpression,
							typeOfEducation: values.typeOfEducation.value,
							gender: values.gender.value,
							educationStatus: values.educationStatus.value,
						})
							.then((response) => {
								if (response >= 200 && response < 300) {
									setFileState([])
									formikBag.resetForm()
									formikRef.current!.values = {}
									createAlert(
										{
											title: translate(
												'grantApplicationForm.notification.successTitle'
											),
											message: translate(
												'grantApplicationForm.notification.successMessage'
											),
										},
										{
											duration: 15000,
										}
									)
								} else {
									createAlert(
										{
											title: translate(
												'grantApplicationForm.notification.errorTitle'
											),
											message: translate(
												response === 415
													? 'grantApplicationForm.notification.invalidFileFormat'
													: response === 422
													? 'grantApplicationForm.notification.failedFileUpload'
													: 'grantApplicationForm.notification.errorMessage'
											),
											type: 'alert',
										},
										{
											duration: Infinity,
										}
									)
								}
							})
							.finally(() => {
								setIsSubmitting(false)
							})
					}}
					validationSchema={Yup.object().shape({
						fullName: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						personalId: Yup.string()
							.required(
								translate('grantApplicationForm.validationErrors.required')
							)
							.test(
								'personalId',
								translate('grantApplicationForm.validationErrors.personalId'),
								(value) => {
									if (value) return kennitala.isValid(value)
									else return false
								}
							),
						streetAddress: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						city: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						postalCode: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						email: Yup.string()
							.required(
								translate('grantApplicationForm.validationErrors.required')
							)
							.email(translate('grantApplicationForm.validationErrors.email')),
						phone: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						age: Yup.number()
							.required(
								translate('grantApplicationForm.validationErrors.required')
							)
							.min(0, translate('grantApplicationForm.validationErrors.age')),
						gender: Yup.object().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						typeOfEducation: Yup.object().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						educationName: Yup.string().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						educationStatus: Yup.object().required(
							translate('grantApplicationForm.validationErrors.required')
						),
						recaptcha: Yup.number()
							.required(
								translate('grantApplicationForm.validationErrors.required')
							)
							.test(
								'recaptcha',
								translate('grantApplicationForm.validationErrors.recaptcha'),
								(value) => {
									const correctAnswer = calculateCorrectAnswer(
										randomMathExpression!
									)
									return value?.toString() === correctAnswer
								}
							),
						files: Yup.array().test(
							'files',
							translate('grantApplicationForm.validationErrors.missingFiles'),
							(value) => {
								return !(value && value.length === 0)
							}
						),
					})}
				>
					{(formikProps: FormikProps<any>) => {
						return (
							<StyledForm>
								<div>
									<InputRowContainer>
										<FormikInputText
											label={translate('grantApplicationForm.labels.fullName')}
											formikName={'fullName'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.fullName'
											)}
										/>
										<FormikInputText
											label={translate(
												'grantApplicationForm.labels.personalId'
											)}
											formikName={'personalId'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.personalId'
											)}
											onChange={(e) => {
												e.preventDefault()
												const { value } = e.target
												const regex = new RegExp('^[0-9]{0,6}-?[0-9]{0,4}$')
												if (regex.test(value)) {
													formikProps.setFieldValue('personalId', value)
												}
											}}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<FormikInputText
											label={translate(
												'grantApplicationForm.labels.streetAddress'
											)}
											formikName={'streetAddress'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.streetAddress'
											)}
										/>
										<FormikInputText
											label={translate('grantApplicationForm.labels.city')}
											formikName={'city'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.city'
											)}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<FormikInputText
											label={translate(
												'grantApplicationForm.labels.postalCode'
											)}
											formikName={'postalCode'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.postalCode'
											)}
										/>
										<FormikInputText
											label={translate('grantApplicationForm.labels.email')}
											formikName={'email'}
											type='email'
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.email'
											)}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<FormikInputText
											label={translate('grantApplicationForm.labels.phone')}
											formikName={'phone'}
											type='tel'
											inputMode='tel'
											fullWidth={false}
											autoComplete='tel'
											placeholder={translate(
												'grantApplicationForm.placeholders.phone'
											)}
											onChange={(e) => {
												e.preventDefault()
												const { value } = e.target
												const regex = new RegExp('^\\d{0,7}$|^\\d{3}-\\d{0,4}$')

												if (regex.test(value)) {
													formikProps.setFieldValue('phone', value)
												}
											}}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<FormikInputText
											label={translate('grantApplicationForm.labels.age')}
											formikName={'age'}
											type='number'
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.age'
											)}
										/>
										<InputSelect
											id='gender'
											label={translate('grantApplicationForm.labels.gender')}
											onChange={(selected) => {
												formikProps.setFieldValue('gender', selected)
											}}
											value={formikProps.values.gender ?? ''}
											options={GENDER_OPTIONS}
											placeholder={translate(
												'grantApplicationForm.placeholders.gender'
											)}
											onBlur={() =>
												formikProps.setFieldTouched('gender', true, true)
											}
											formikName={'gender'}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<InputSelect
											id='typeOfEducation'
											label={translate(
												'grantApplicationForm.labels.typeOfEducation'
											)}
											onChange={(selected) => {
												formikProps.setFieldValue('typeOfEducation', selected)
											}}
											value={formikProps.values.typeOfEducation ?? ''}
											options={EDUCATION_OPTIONS}
											placeholder={translate(
												'grantApplicationForm.placeholders.typeOfEducation'
											)}
											onBlur={() =>
												formikProps.setFieldTouched(
													'typeOfEducation',
													true,
													true
												)
											}
											formikName={'typeOfEducation'}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<FormikInputText
											label={translate(
												'grantApplicationForm.labels.educationName'
											)}
											formikName={'educationName'}
											fullWidth={false}
											placeholder={translate(
												'grantApplicationForm.placeholders.educationName'
											)}
										/>
									</InputRowContainer>
									<InputRowContainer>
										<InputSelect
											id='educationStatus'
											label={translate(
												'grantApplicationForm.labels.educationStatus'
											)}
											onChange={(selected) => {
												formikProps.setFieldValue('educationStatus', selected)
											}}
											value={formikProps.values.educationStatus ?? ''}
											options={EDUCATION_STATUS_OPTIONS}
											placeholder={translate(
												'grantApplicationForm.placeholders.educationStatus'
											)}
											onBlur={() =>
												formikProps.setFieldTouched(
													'educationStatus',
													true,
													true
												)
											}
											formikName={'educationStatus'}
										/>
									</InputRowContainer>
								</div>
								<Separator />
								<StyledTitle>
									{translate(
										'grantApplicationForm.sections.supportingDocumentsTitle'
									)}
								</StyledTitle>
								{sliceData.primary.documents_description && (
									<StyledRichText
										content={sliceData.primary.documents_description}
									/>
								)}
								<InputRowContainer>
									<InputFile
										formikName='files'
										label={`${translate(
											'grantApplicationForm.labels.attachment'
										)}`}
										buttonText={translate(
											'grantApplicationForm.labels.attachmentButton'
										)}
										onFileChange={(ev) => {
											setFileState((prev) => [...prev, ev.target.files[0]])
											formikProps.validateForm()
										}}
										removeFile={(index: number) =>
											setFileState([
												...fileState?.slice(0, index),
												...fileState?.slice(index + 1),
											])
										}
										files={fileState}
										accept='.pdf,.doc,.docx'
										mimeTypes={{
											doc: 'application/msword',
											docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
											pdf: 'application/pdf',
										}}
									/>
								</InputRowContainer>
								<InputRowContainer>
									<FormikInputText
										label={convertRandomMathExpressionToString(
											randomMathExpression,
											router.locale
										)}
										formikName={'recaptcha'}
										fullWidth={false}
										placeholder={translate(
											'grantApplicationForm.placeholders.recaptcha'
										)}
										type='number'
									/>
								</InputRowContainer>
								<StyledSendButton
									disabled={isSubmitting}
									loading={isSubmitting}
									type='submit'
								>
									{isSubmitting && <StyledBarSpinner />}
									<div>{translate('grantApplicationForm.labels.submit')}</div>
								</StyledSendButton>
							</StyledForm>
						)
					}}
				</Formik>
			</StyledHalfContent>
		</StyledFullBleedGrid>
	)
}

export default GrantApplication

const InputRowContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacer-sm);

	${media.phone`
		flex-direction: column;
		gap: 0;
	`};
`

const StyledSendButton = styled(Button)`
	display: block;
	margin-left: auto;

	${media.phone`
		width: 100%;
	`};
`

const StyledBarSpinner = styled(BarSpinner)`
	position: absolute;
	right: 0;
	left: 0;
	width: 100%;
	margin: 0 auto;
	gap: 5px;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.gold550};

	span {
		background-color: ${(props) => props.theme.colors.white};
	}
`

const StyledFullBleedGrid = styled(FullBleedGrid)<{ hasBackground: boolean }>`
	padding: var(--spacer-base) 0 var(--spacer-xl);
	${(p) =>
		p.hasBackground ? `background-color: ${p.theme.colors.grey50};` : ''}

	${media.phone`
		padding-bottom: var(--spacer-base);
	`};
`

const StyledHalfContent = styled.div`
	grid-column: 2 / span 4;

	&:last-of-type {
		grid-column: 7 / span 6;
	}

	${media.tablet`
		&:last-of-type {
			grid-column: 7 / span 7;
		}
	`};

	${media.phone`
		grid-column: 2 / -2;

		&:last-of-type {
			grid-column: 2 / -2;
			margin-top: var(--spacer-lg);
		}
	`};
`

const StyledFormInfo = styled.div`
	margin: 0 0 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid ${(props) => props.theme.colors.gold100};
	color: ${(props) => props.theme.colors.grey600};

	${media.phone`
		margin-bottom: var(--spacer-sm);
		padding-bottom: var(--spacer-sm);
	`};
`

const StyledTitle = styled.h1`
	font-size: 22px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	margin: 0;
`

const Separator = styled.div`
	height: 1px;
	background-color: ${(props) => props.theme.colors.gold100};
`

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 24px;
`

const StyledRichText = styled(RichText)`
	& *:not(strong) {
		font-weight: 400 !important;
	}
`
