import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'
import { Grid } from '../Layout/Grid'
import SectionTitle from '../Section/SectionTitle'

export interface IMediaCardSlice extends IBaseSlice {
	primary: {
		section_title: string
		description1: string
	}
	items: {
		media_title: string
		media_preview: IPrismicImage
		media_link: IPrismicImage
		media_link_text: string
		secondary_media_link: IPrismicImage
		secondary_media_link_text: string
	}[]
	slice_type: 'media_card'
}

export interface IMediaCard {
	sliceData: IMediaCardSlice
}

const MediaCard = ({ primary, items }: IMediaCardSlice) => {
	return (
		<StyledGrid>
			<SectionTitle title={primary.section_title} />
			<TextSection> {primary.description1} </TextSection>
			<MediaContainer>
				{map(items, (item, index) => (
					<MediaCardContainer key={index}>
						<ImageWrapper>
							<SImg
								src={item.media_link.url}
								width={item.media_link.dimensions?.width}
								height={item.media_link.dimensions?.height}
							/>
						</ImageWrapper>
						<StyledMediaCard>
							<StyledTitle>{item.media_title}</StyledTitle>
							<StyledAnchor
								target='_blank'
								href={item.media_link.url}
								key={item.media_link_text}
							>
								{item.media_link_text}
							</StyledAnchor>
							{item.secondary_media_link && item.secondary_media_link_text && (
								<StyledAnchor
									target='_blank'
									href={item.secondary_media_link.url}
									key={item.secondary_media_link_text}
								>
									{item.secondary_media_link_text}
								</StyledAnchor>
							)}
						</StyledMediaCard>
					</MediaCardContainer>
				))}
			</MediaContainer>
		</StyledGrid>
	)
}

export default MediaCard

const MediaContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	grid-column: 1 / -1;
	justify-content: space-around;
	gap: 32px;
`

const StyledGrid = styled(Grid)`
	padding: 40px 0px;
	font-family: 'AkzidenzGroteskPro';
`

const TextSection = styled.div`
	grid-column: 1 / 7;
	padding: 40px 0px;
	font-weight: 400;
	font-size: 1rem;
`

const MediaCardContainer = styled.div`
	width: 320px;
`

const StyledMediaCard = styled.div`
	line-height: 24px;
	font-size: 1rem;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
`

const ImageWrapper = styled.div`
	height: 200px;
`

const SImg = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;

	object-position: top;
`

const StyledTitle = styled.h2`
	margin: 20px 0;
	color: ${(props) => props.theme.colors.black};
	font-weight: 500;
	font-size: 1.375rem;
`

const StyledAnchor = styled(Link)`
	color: ${(props) => props.theme.colors.gold550};
	text-decoration: underline;
`
