import * as React from 'react'
import styled from 'styled-components'

export interface ICardProps {
	className?: string
	children?: React.ReactNode
}

// * Renders children in a card
export function Card({ children, className }: ICardProps) {
	return <StyledCardContainer className={className}>{children}</StyledCardContainer>
}

export const StyledCardContainer = styled.div`
	box-shadow: 1px 8px 40px rgb(0 0 0 / 15%);
	background: #ffffff;
	display: inline-block;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.4);
	}

	* {
		width: auto;
	}
`
