import { RichTextBlock } from 'prismic-reactjs'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Autoplay, Controller, EffectFade, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { map } from 'lodash'
import { Navigation, Pagination } from 'swiper'

import { IBaseSlice, IPrismicImage } from '../../../types/prismic/baseTypes'
import { FullBleedGrid } from '../../Layout/Grid'
import LinearGradientBackground from '../../Layout/LinearGradientBackground'
import { devices, media } from '../../../utils/style-utils'
import SectionTitle from '../../Section/SectionTitle'
import RichText from '../../RichText/RichText'

import Dots from './Dots'
import CarouselBottomImage from './CarouselBottomImage'

import 'swiper/css'
import 'swiper/css/effect-fade'

export interface Slide {
	text: RichTextBlock[]
	section_title?: string
	image?: IPrismicImage
	image1?: IPrismicImage
	image2?: IPrismicImage
	image3?: IPrismicImage
	bottomtext: RichTextBlock[]
}

export interface ITextCarouselSlice extends IBaseSlice {
	slice_type: 'text_carousel'
	primary: {
		title: string
		description: string
		image: IPrismicImage
	}
	items: Slide[]
}

interface IProps {
	sliceData: ITextCarouselSlice
}

const INTERVAL: number = 15000

const TextCarouselSlice: React.FC<IProps> = ({ sliceData }) => {
	const [currentSlide, setCurrentSlide] = useState(0)

	return (
		<Grid>
			<ImageWrapper backgroundUrl={sliceData.primary.image.url} />
			<TextContainer>
				<StyledSwiper
					effect='fade'
					fadeEffect={{ crossFade: true }}
					modules={[
						Autoplay,
						Controller,
						Navigation,
						Pagination,
						Mousewheel,
						EffectFade,
					]}
					navigation={{ nextEl: '#swiper-forward', prevEl: '#swiper-back' }}
					autoplay={{ delay: INTERVAL, reverseDirection: true }}
					simulateTouch={false}
					pagination={{ clickable: true }}
					onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
				>
					{map(sliceData.items, (item, index) => {
						return (
							<SwiperSlide key={`slide-${index}`}>
								<SwipeContainer>
									{item.section_title && (
										<SectionTitleWrapper>
											<SectionTitle>{item.section_title}</SectionTitle>
										</SectionTitleWrapper>
									)}
									<RichText
										content={item.text}
										serializerOptions={{
											enableFileIconAnchors: false,
											enableMediaLinks: false,
										}}
									/>
									<ImagesContainer>
										{sliceData.items.length > 1 && (
											<Dots
												items={sliceData.items}
												currentSlide={currentSlide}
												setCurrentSlide={setCurrentSlide}
											/>
										)}
										<Images>
											<CarouselBottomImage image={item.image} />
											<CarouselBottomImage image={item.image1} />
											<CarouselBottomImage image={item.image2} />
											<CarouselBottomImage image={item.image3} />
										</Images>
									</ImagesContainer>
								</SwipeContainer>
							</SwiperSlide>
						)
					})}
				</StyledSwiper>
			</TextContainer>
		</Grid>
	)
}

const SectionTitleWrapper = styled.div`
	margin-top: calc(var(--spacer-base) - var(--spacer-2xl));
	margin-bottom: var(--spacer-base);

	${media.phone`
		margin-top: 0px;
		margin-bottom: var(--spacer-sm);
	`};
`

const Grid = styled(FullBleedGrid)`
	width: 100%;
	max-height: 720px;
	height: 100%;
	grid-template-rows: minmax(256px, 720px);
	${devices.safariDesktop`
		grid-template-rows: minmax(750px, 100%);
		${media.phone`
		grid-template-rows: min-content;
		`};
	`}

	${media.phone`
		min-height: 454px;
		max-height: unset;
		grid-template-rows: 384px auto;
	`};
`

const TextContainer = styled.div`
	grid-column: 9 / span 5;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.phone`
		grid-column: 3 / span 10;
	`}
`

const ImagesContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 100%;
	flex-wrap: nowrap;
`

const Images = styled.div`
	display: flex;

	gap: 8px;
`

const StyledSwiper = styled(Swiper)`
	margin-inline: unset;
	height: 100%;
	margin-top: var(--spacer-2xl);
	margin-bottom: 160px;

	${media.phone`
		margin-top: var(--spacer-lg);
		margin-bottom: var(--spacer-md);
	`};

	overflow: visible;

	.swiper-pagination {
		display: none;
	}

	.swiper-pagination-bullet {
		background: ${({ theme }) => theme.colors.grey200};
		opacity: 1;
	}

	.swiper-pagination-bullet-active {
		background: ${({ theme }) => theme.colors.gold550};
	}
`

const SwipeContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	p {
		font-family: 'Adobe Caslon Pro';
		font-size: 18px;
		line-height: 32px;
	}
`

export default TextCarouselSlice

const ImageWrapper = styled(LinearGradientBackground)<{
	backgroundUrl: string
}>`
	margin-right: -8px;
	background-size: cover;
	height: 100%;
	grid-column: 1 / span 7;

	${media.phone`
		grid-column: 1 / -1;
		min-height: unset;
		max-height: 384px;
		height: 384px;
		margin-right: -4px;		
	`}
`
