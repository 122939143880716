import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'

import LinkCard from './LinkCard'

interface IProps {
	items: {
		url: string
		card_title: string
	}[]
}

// * Renders a vertical list of link cards
const LinkCardList: React.FC<IProps> = ({ items }) => {
	return (
		<StyledContainer>
			{map(items, (link, index) => (
				<LinkCard key={`${link.card_title}-${index}`} link={link} />
			))}
		</StyledContainer>
	)
}

export default LinkCardList

// * Exported in order to be extended by consumer
export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	margin-top: 32px;
	margin-bottom: 48px;
`
