import Button, { ButtonBackgroundType, IconPlacement } from '@kvika/button'
import { PlusAdd } from '@kvika/icons'
import styled, { css } from 'styled-components'
import { sanitize } from 'isomorphic-dompurify'
import { useRef, useState } from 'react'
import { ErrorMessage, Field, FieldArray } from 'formik'

import Trash from '../../icons/Trash'
import { fileToBase64 } from '../../../utils/formUtils'
import { InputWrapper } from '../../FormikForm/FormikInputText/FormikInputText'
import useLocalization from '../../../hooks/utils/use-localization'

const allowedExtensions = ['pdf', 'doc', 'docx']
const acceptExtensions = '.pdf,.doc,.docx'

interface IInputFileProps {
	label: string
	buttonText: string
	onFileChange: (event: any) => void
	removeFile: (index: number) => void
	files?: File[]
	accept?: string
	nameRegex?: RegExp
	maxSize?: number
	formikName: string
	mimeTypes?: {}
}

export const changeFileFormat = async (files: File[], types?: string[]) => {
	if (!types) types = allowedExtensions
	const filesArr = await Promise.all(
		files.map(async (file, index) => ({
			content_type: file ? file.type : undefined,
			content: file ? await fileToBase64(file) : undefined,
			file_name: file ? file.name : undefined,
		}))
	)

	return filesArr
}

const secureNameRegex =
	/^[A-Za-zÁáÞþÐðÆæÖöÞþÝýÚúÉéÍíÆæEéÖöÞþÖöÚúÓó\xF3\u0301\u03080-9¨_\-\. ]{3,30}$/

const InputFile = (props: IInputFileProps) => {
	const {
		removeFile,
		label,
		buttonText,
		onFileChange,
		files,
		accept = acceptExtensions,
		nameRegex = secureNameRegex,
		maxSize = 26214400, //25MB
		formikName,
		mimeTypes,
	} = props
	const [errorMessage, setErrorMessage] = useState('')
	const { translate } = useLocalization()
	const inputRef = useRef<HTMLInputElement>(null)

	const openDialog = () => {
		if (typeof window) {
			// * We only want to open the file dialog if we are in a browser
			if (inputRef && typeof inputRef.current?.click === 'function') {
				return inputRef.current.click()
			}
		}

		return null
	}

	const validFile = (event) => {
		const fileInput = event.target
		const file = fileInput.files[0]

		if (file) {
			// Check duplicate files
			if (files && files.length >= 1) {
				const sameFile = files.find((item) => item.name === file.name)

				if (sameFile) {
					setErrorMessage(
						translate('fileInput.validationErrors.duplicateFiles')
					)
					return false
				}
			}

			// Check file size
			if (file.size > maxSize) {
				setErrorMessage(translate('fileInput.validationErrors.size'))
				return false
			}

			// Check file name
			if (!nameRegex.test(file.name) || sanitize(file.name) !== file.name) {
				setErrorMessage(translate('fileInput.validationErrors.name'))
				return false
			}

			// Check file extension
			const extensions = accept.split(',').map((element, index) => {
				return element.trim().replace('.', '')
			})
			const fileExtension = file.name.split('.').pop().toLowerCase()
			if (!extensions.includes(fileExtension)) {
				setErrorMessage(translate('fileInput.validationErrors.type'))
				return false
			}

			// Check file mime type
			if (mimeTypes && !mimeTypes[fileExtension] === file.type) {
				setErrorMessage(translate('fileInput.validationErrors.type'))
				return false
			}

			return true
		}
		return false
	}

	return (
		<FieldArray name={formikName}>
			{({ push, remove }) => (
				<Field name={formikName}>
					{({ meta }) => {
						return (
							<InputWrapper fullWidth={false}>
								<label>{label}</label>
								<input
									ref={inputRef}
									type='file'
									style={{ display: 'none' }}
									onChange={(e) => {
										setErrorMessage('')
										if (validFile(e)) {
											if (e.target.files) {
												onFileChange(e)
												push(e.target.files[0].name)
											}
										}
									}}
									onClick={(e) => {
										e.currentTarget.value = ''
									}} // Fixes the double file error in chrome and edge
									accept={accept}
								/>

								<List>
									{files &&
										files.map((file, index) => {
											return (
												<FileItem key={file.name}>
													<Button
														onClick={() => {
															removeFile(index)
															remove(index)
														}}
														icon={<Trash />}
														backgroundType={ButtonBackgroundType.Filled}
														iconPlacement={IconPlacement.RIGHT}
													>
														{file.name}
													</Button>
												</FileItem>
											)
										})}
								</List>
								<StyledButton
									backgroundType={ButtonBackgroundType.Outlined}
									icon={<PlusAdd />}
									id='fileButton'
									onClick={openDialog}
								>
									{buttonText}
								</StyledButton>

								{formikName && meta.error && meta.touched && (
									<StyledValidationError>
										<ErrorMessage name={formikName} />
									</StyledValidationError>
								)}

								{errorMessage && (
									<StyledValidationError>{errorMessage}</StyledValidationError>
								)}
							</InputWrapper>
						)
					}}
				</Field>
			)}
		</FieldArray>
	)
}

export default InputFile

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const FileItem = styled.div`
	&:first-of-type {
		margin-top: 15px;
	}
	&:last-of-type {
		margin-bottom: 20px;
	}
	& button {
		min-height: 48px;
		height: auto;
		& div:first-of-type {
			align-items: center;
			justify-content: center;

			& div:last-of-type {
				height: 20px;
			}
		}
		${(p) =>
			css`
				background-color: ${p.theme.colors.grey50};
				:hover:not([disabled]) {
					background-color: ${p.theme.colors.grey50};
				}
			`}
	}
	& div {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: left;
		${(p) =>
			css`
				color: ${p.theme.colors.grey800};
			`}
	}
`

const StyledButton = styled(Button)`
	height: 48px;
	max-width: 174px;
	padding: 0;
	${(p) =>
		css`
			color: ${p.theme.colors.gold550};
			border: 2px solid ${p.theme.colors.gold550};
			&:hover:not([disabled]) {
				border-color: ${p.theme.colors.gold500};
				color: ${p.theme.colors.gold500};
			}
		`}
`

const StyledValidationError = styled.div`
	padding-top: 5px;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: ${(props) => props.theme.colors.error};
`
