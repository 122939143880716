import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { media } from '../../../utils/style-utils'
import PrismicLink from '../../Links/PrismicLink'
import PrismicImg from '../../PrismicImage/PrismicImg'
import { Arrow } from '../../icons'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { IBaseSlice, IPrismicImage, IPrismicLink } from '../../../types/prismic/baseTypes'

export interface IHeroLinkListSlice extends IBaseSlice {
	primary: {
		image: IPrismicImage
		imageleft?: boolean
		showLinkIcon?: boolean
	}
	items: {
		link_text: string
		link: IPrismicLink
	}[]
	
	slice_type: 'hero_link_list'
}

interface IProps {
	sliceData: IHeroLinkListSlice
}

const HeroLinkListSlice: React.FC<IProps> = ({ sliceData }) => {
	return (
		<StyledContainer reverse={sliceData.primary.imageleft}>
			<StyledListContainer>
				<MaxWidthContainer expanded={sliceData.primary.showLinkIcon}>
					<Ruler />
					{map(sliceData.items, (item, index) => (
						<React.Fragment key={index}>
							<StyledLink link={item.link}>
								{item.link_text}
								{sliceData.primary.showLinkIcon && (
									<Arrow rotate={180} width={18} stroke={'#B89E74'} />
								)}
							</StyledLink>
							<Ruler />
						</React.Fragment>
					))}
				</MaxWidthContainer>
			</StyledListContainer>

			<ImageWrapper reverse={sliceData.primary.imageleft}>
				<CoverImage image={sliceData?.primary.image} />
			</ImageWrapper>
		</StyledContainer>
	)
}

export default HeroLinkListSlice

// These positioning parameters are necessary for the image to scale properly.
const ImageWrapper = styled.div<{ reverse?: boolean }>`
	position: absolute;
	right: ${(props) => (props.reverse ? '50%;' : '0;')};
	left: ${(props) => (props.reverse ? '0;' : '50%;')};
	top: 0;
	bottom: 0;
	overflow: hidden;
	object-fit: cover;
	${media.phone`
		display: none;
	`}
`

const CoverImage = styled(PrismicImg)`
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
`

const MaxWidthContainer = styled.div<{ expanded?: boolean }>`
	max-width: ${(props) => (props.expanded ? 'none' : '360px')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	gap: 16px;
`

const Ruler = styled.hr`
	width: 100%;
	padding: 0;
	margin: 0;
	background-color: ${(props) => props.theme.colors.grey600};
	border: none;
	height: 1px;
`

const StyledLink = styled(PrismicLink)`
	display: flex;
	flex: 1;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${(props) => props.theme.colors.gold400};
	text-decoration: none;
	justify-content: space-between;
	align-items: center;
`

const StyledContainer = styled.div<{ reverse?: boolean }>`
	position: relative;
	display: flex;
	${(props) =>
		props.reverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
`

const StyledListContainer = styled.div`
	width: 50%;
	padding: 80px;
	background-color: ${(props) => props.theme.colors.grey800};

	align-items a:last-of-type {
		border: none;
	}

	${media.tablet`
		padding: 30px;
	`}

	${media.phone`
		width: 100%;
		padding: 0 20px;
	`}
`
