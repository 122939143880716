import styled, { css } from 'styled-components'

import { ICurrencyRates } from './Calculator'

export interface ICalculatorSingleTabProps {
	name: string
	currencyType: keyof ICurrencyRates
	isActive: boolean
	onCurrencyClick: (currencyType: keyof ICurrencyRates) => void
}

const CalculatorSingleTab = ({
	name,
	currencyType,
	isActive,
	onCurrencyClick,
}: ICalculatorSingleTabProps) => {
	const onClickHandler = () => {
		onCurrencyClick(currencyType)
	}

	return (
		<StyledTabList>
			<StyledCalculatorSingleTab isActive={isActive} onClick={onClickHandler}>
				{name}
			</StyledCalculatorSingleTab>
		</StyledTabList>
	)
}

export default CalculatorSingleTab

const StyledTabList = styled.li``

const StyledCalculatorSingleTab = styled.a<{ isActive: boolean }>`
	${({ isActive, theme: { colors } }) => css`
		line-height: 56px;
		border-radius: 4px 4px 0 0;
		position: relative;
		display: block;
		${isActive
			? `color : ${colors.gold550};
			 font-weight: 500;`
			: `color: ${colors.grey800};
			font-weight: 400;`}
		text-decoration: none;
		cursor: pointer;
		border-bottom: 1px solid ${colors.gold100};
	`}
`
