import React from 'react'

import { KvikaSVGProps } from './types'

const TMLogo = ({
	height = '96',
	fill = 'none',
	width = '96',
}: KvikaSVGProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 96 96'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M96 0H0V96H96V0Z' fill='#056954' />
			<path
				d='M33 63.9961H25V39.0005H17V31.9961H41V39.0005H33V63.9961Z'
				fill='white'
			/>
			<path
				d='M78.5 64L73.6245 32H66.992L60.0644 49.1036L53.5215 32H46.9748L41.5 64H49.8392L52.1005 48.5365L58.3111 64H61.646L68.1441 48.5365L70.1178 64H78.5Z'
				fill='white'
			/>
		</svg>
	)
}

export default TMLogo
