import { useQuery } from 'react-query'
import { filter, orderBy, pick, pickBy } from 'lodash'

import { ICurrencyRates } from '../../components/Calculator/Calculator'

const CURRENCY_API_ROUTE =
	process.env.NODE_ENV === 'development'
		? 'https://api.staging.kvika.is/marketdata/currencies/rates'
		: 'https://api.kvika.is/marketdata/currencies/rates'

const TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000

const CURRENCIES_TO_LIST = [
	'EUR',
	'USD',
	'DKK',
	'SEK',
	'NOK',
	'GBP',
	'CAD',
	'CHF',
	'JPY',
]

const filterCurrencies = (currencyRate) =>
	filter(currencyRate, (value) =>
		CURRENCIES_TO_LIST.includes(value.currencyCode)
	).sort((a, b) => {
		return (
			CURRENCIES_TO_LIST.indexOf(a.currencyCode) -
			CURRENCIES_TO_LIST.indexOf(b.currencyCode)
		)
	})

const fetchCurrencyData = async (): Promise<ICurrencyRates> => {
	return fetch(CURRENCY_API_ROUTE).then((data) => {
		if (data.status !== 200) throw 'Generic error'
		return data.text().then((text) => {
			const parsed: ICurrencyRates = JSON.parse(text)

			const filtered = {
				exchangeRates: filterCurrencies(parsed.exchangeRates),
				noteRates: filterCurrencies(parsed.noteRates),
				visaRates: filterCurrencies(parsed.visaRates),
			}

			return filtered
		})
	})
}

export default function useCurrencyData(
	options?: { staleTime?: number; cacheTime?: number } | undefined
) {
	return useQuery('currencyData', () => fetchCurrencyData(), {
		staleTime: options?.staleTime || TWELVE_HOURS_IN_MS,
		cacheTime: options?.cacheTime || TWELVE_HOURS_IN_MS,
	})
}
