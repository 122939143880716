import { RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import { FullBleedGrid } from '../Layout/Grid'
import RichText from '../RichText/RichText'

export interface ISliceShowcaseTitle extends IBaseSlice {
	slice_type: 'slice_showcase_title'
	primary: {
		slice_name: string
		description1: RichTextBlock[]
		status: string
	}
}

interface Props {
	sliceData: ISliceShowcaseTitle
}

const SliceShowcaseTitle: React.FC<Props> = ({ sliceData }) => {
	const isDeprecated = sliceData.primary.status === 'Deprecated'
	const isProbablyDeprecated =
		sliceData.primary.status === 'Probably deprecated'
	const isWIP = sliceData.primary.status === 'WIP'
	const isReady = sliceData.primary.status === 'Ready for use'
	const status =
		(isDeprecated && 'deprecated') ||
		(isProbablyDeprecated && 'probably-deprecated') ||
		(isWIP && 'wip') ||
		(isReady && 'ready')

	return (
		<FullBleedGrid>
			<ShowcaseWrapper>
				<HeadingWrapper>
					<h1>{sliceData.primary.slice_name}</h1>
					{status && (
						<Status status={status}>{sliceData.primary.status}</Status>
					)}
				</HeadingWrapper>
				<RichText content={sliceData.primary.description1} />
			</ShowcaseWrapper>
		</FullBleedGrid>
	)
}

export default SliceShowcaseTitle

const ShowcaseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 1 / -1;
	margin-top: var(--spacer-lg);
	margin-bottom: var(--spacer-md);
	padding-inline: var(--spacer-base);
	padding-block: var(--spacer-md);
	border-top: 1px solid ${({ theme }) => theme.colors.grey100};
`

const Status = styled.div<{ status: string }>`
	font-size: .875rem;
	border: 1px solid black;
	background: blue;
	border-radius: 24px;
	padding: 0px 8px;

	${({ status, theme }) =>
		status === 'deprecated' &&
		css`
			background: ${theme.colors.errorLight};
			border: 1px solid ${theme.colors.error};
			color: black;
		`}

	${({ status, theme }) =>
		status === 'probably-deprecated' &&
		css`
			background: ${theme.colors.warningLight};
			border: 1px solid ${theme.colors.warningDark};
		`}

  ${({ status, theme }) =>
		status === 'wip' &&
		css`
			background: ${theme.colors.warningLight};
			border: 1px solid ${theme.colors.warning};
		`}

  ${({ status, theme }) =>
		status === 'ready' &&
		css`
			background: ${theme.colors.successLight};
			border: 1px solid ${theme.colors.success};
		`}
`
const HeadingWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`
