import React from 'react'
import styled, { css } from 'styled-components'

import useLocalization from '../../hooks/utils/use-localization'
import { nameof } from '../../utils/tsHelpers'

import { ICurrencyRates } from './Calculator'
import CalculatorSingleTab from './CalculatorSingleTab'

interface ICalculatorTabContainerProps {
	currencyType: keyof ICurrencyRates
	changeCurrencyType: (currencyType: keyof ICurrencyRates) => void
}

const CalculatorTabContainer = ({
	currencyType,
	changeCurrencyType,
}: ICalculatorTabContainerProps) => {
	const { translate } = useLocalization()
	return (
		<StyledTabContainer>
			<CalculatorSingleTab
				name={translate('calculator.generalExchangeRate')}
				currencyType={nameof<ICurrencyRates>('exchangeRates')}
				isActive={currencyType === nameof<ICurrencyRates>('exchangeRates')}
				onCurrencyClick={changeCurrencyType}
			/>
			<CalculatorSingleTab
				name={translate('calculator.banknoteExchangeRate')}
				currencyType={nameof<ICurrencyRates>('noteRates')}
				isActive={currencyType === nameof<ICurrencyRates>('noteRates')}
				onCurrencyClick={changeCurrencyType}
			/>
			<CalculatorSingleTab
				name={translate('calculator.visaExchangeRate')}
				currencyType={nameof<ICurrencyRates>('visaRates')}
				isActive={currencyType === nameof<ICurrencyRates>('visaRates')}
				onCurrencyClick={changeCurrencyType}
			/>
		</StyledTabContainer>
	)
}

export default CalculatorTabContainer

const StyledTabContainer = styled.ul`
	${({ theme: { colors } }) => css`
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0 0 24px;
		margin: 39px 24px 0px 0px;
		font-size: 1rem;
		width: clamp(105px, 264px, 264px);

		a {
			padding-left: 8px;
		}
	`}
`
