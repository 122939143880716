import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import styled, { css } from 'styled-components'

import { IPage } from '../../../types/prismic/pages/generalPage'
import { IBaseSlice } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import HtmlSerializer from '../../HtmlSerializer/htmlSerializer'
import { FullBleedGrid } from '../../Layout/Grid'
import ShortRuler from '../../Ruler/ShortRuler'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'


export interface IColumnContentSlice extends IBaseSlice {
	primary: {
		first_column: RichTextBlock[]
		second_column: RichTextBlock[]
		content_heading?: string
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	slice_type: 'column_content'
}

interface Props {
	sliceData: IColumnContentSlice
}

const ColumnContent: React.FC<Props> = ({ sliceData }) => {
	return (
		<StyledFullBleedGrid backgroundColor={sliceData.primary.section_color}>
			<FullBleedSectionTitle title={sliceData.primary.section_title} />
			{sliceData.primary.content_heading && (
				<ContentHeadingWrapper>
					<ContentHeading>{sliceData.primary.content_heading}</ContentHeading>
					<ShortRuler />
				</ContentHeadingWrapper>
			)}
			<TextContainer>
				<RichText
					render={sliceData.primary.first_column}
					htmlSerializer={HtmlSerializer}
				/>
			</TextContainer>
			<TextContainer>
				<RichText
					render={sliceData.primary.second_column}
					htmlSerializer={HtmlSerializer}
				/>
			</TextContainer>
		</StyledFullBleedGrid>
	)
}

export default ColumnContent

const ContentHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 2 / -2;
`

const ContentHeading = styled.h1`
	margin-top: 0px;
	margin-bottom: 8px;
`

const StyledFullBleedGrid = styled(FullBleedGrid) <{ backgroundColor?: string }>`
	padding-top: 80px;
	padding-bottom: 120px;
	grid-row: 2;
	${({ theme: { colors }, backgroundColor }) => css`
		background-color: ${backgroundColor
			? colors[backgroundColor.toLowerCase()]
			: 'inherit'};
	`};
`

const TextContainer = styled.div`
	grid-column: 2 / span 5;

	&:last-of-type {
		grid-column: 8 / span 6;
	}

	${media.tablet`
		grid-column: 2 / -2 !important;
	`};

	.block-img img {
		max-width: 100%;
	}
`
