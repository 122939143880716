import React from 'react'
import styled from 'styled-components'

interface Props {
	src: string
}

const Vimeo: React.FC<Props> = ({ src }) => {
	if (!src) return null

	return (
		<StreamContainer>
			<StyledIframe
				src={src}
				frameBorder='0'
				loading='eager'
				allow='autoplay; fullscreen; picture-in-picture'
			/>
		</StreamContainer>
	)
}

export default Vimeo

const StreamContainer = styled.div``

const StyledIframe = styled.iframe`
	width: 100%;
	aspect-ratio: 16/9;
`
