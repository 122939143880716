import * as React from 'react'
import styled from 'styled-components'

import { IBaseSlice } from '../../types/prismic/baseTypes'
import { media } from '../../utils/style-utils'
import { LineBorder } from '../icons'
import { FullBleedGrid, Grid } from '../Layout/Grid'

export interface IHeroQuote extends IBaseSlice {
	primary: {
		text: string
	}
	slice_label?: string
	slice_type: 'heroquote'
}

export interface IHeroQuoteProps {
	sliceData: IHeroQuote
}

export function HeroQuote({
	sliceData: {
		primary: { text },
	},
}: IHeroQuoteProps) {
	return (
		<StyledQuoteContainer backgroundColor='Grey800'>
			<StyledContainer>
				<LineBorder />
				<StyledHeading>{text}</StyledHeading>
				<LineBorder />
			</StyledContainer>
		</StyledQuoteContainer>
	)
}

const StyledHeading = styled.h1`
	padding: 36px;
	font-weight: 400;
	color: white;

	font-family: AdobeCaslonPro;
	text-align: center;
	font-size: 1.25rem;
	line-height: 2.5rem;

	margin-block: var(--spacer-md);
	padding-block: 0;
`

const StyledContainer = styled.div`
	grid-column: 4 / span 8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.phone`
		grid-column: 2 / span 12;
	`}
`

const StyledQuoteContainer = styled(FullBleedGrid)`
	padding-block: var(--spacer-base);
`
