import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'


import { LinkCard } from '../LinkCard'
import { IBaseSlice } from '../../types/prismic/baseTypes'

export interface ISmallLinkCardListSlice extends IBaseSlice {
	items: [
		link: {
			url: string
			card_title: string
		}
	]
	slice_type: 'link_card'
}

interface IProps {
	sliceData: ISmallLinkCardListSlice
}

const SmallLinkCardListSlice: React.FC<IProps> = ({ sliceData }) => {
	return (
		<StyledContainer>
			{map(sliceData.items, (link, index) => (
				<LinkCard key={`small-link-card-${index}`} link={link} />
			))}
		</StyledContainer>
	)
}

export default SmallLinkCardListSlice

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	margin-top: 30px;
	margin-bottom: 45px;
`
