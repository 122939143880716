import Cleave from 'cleave.js/react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import useLocalization from '../../hooks/utils/use-localization'


export interface ICalculatorInputProps {
	iskValue: number
	saleValue: number
	allowDecimals: boolean
	onInputChange: (newISKValue: number) => void
}

const CalculatorInput = (props: ICalculatorInputProps) => {
	const { translate } = useLocalization()
	const { iskValue, saleValue, onInputChange, allowDecimals } = props
	const [valueAsString, setValueAsString] = useState('0')
	const [calculatedValue, setCalculatedValue] = useState(0)
	const [isFocusActive, setIsFocusActive] = useState(false)

	const roundValue = (newNumber: number) => {
		return parseFloat(newNumber.toFixed(2))
	}
	const roundValueToString = (newNumber: number, isFocusActive: boolean) => {
		if (isFocusActive) {
			return parseFloat(newNumber.toFixed(10)).toString().replace('.', ',') // Fixed JS trailing calculations (X.000000000000001)
		}

		return newNumber.toFixed(2).replace('.', ',')
	}
	const handleFocus = (event: any) => {
		setIsFocusActive(true)
		event.target.select()
	}

	const handleOnBlur = (event: any) => {
		setIsFocusActive(false)
		setCalculatedValue(roundValue(iskValue / saleValue))
		setValueAsString(roundValueToString(iskValue / saleValue, false))
	}

	useEffect(() => {
		setCalculatedValue(roundValue(iskValue / saleValue))
		setValueAsString(roundValueToString(iskValue / saleValue, isFocusActive))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iskValue, saleValue])

	const changeCurrencyValue = (event: any) => {
		setValueAsString(event.target.value)
		if (event.target.rawValue != calculatedValue) {
			onInputChange(parseFloat(event.target.rawValue) * saleValue)
		}
	}

	return (
		<StyledCleaveInput
			placeholder={translate('calculator.enterNumber')}
			options={{
				numeral: true,
				numeralDecimalMark: ',',
				delimiter: '.',
				numeralDecimalScale: allowDecimals ? 10 : 0,
			}}
			onChange={changeCurrencyValue}
			value={valueAsString}
			inputMode='decimal'
			onFocus={handleFocus}
			onBlur={handleOnBlur}
		/>
	)
}

export default CalculatorInput

const StyledCleaveInput = styled(Cleave)`
	text-align: right;
	font-weight: 400;
	font-size: 1rem;
	padding: 16px;
	width: 75%;
	height: 40px;
	margin: 4px 0px;

	/* Neutral/Grey_400 */
	border: 1px solid #999794;
	border-radius: 2px;
`
