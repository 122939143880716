import styled from 'styled-components'

import { IPrismicImage } from '../../../types/prismic/baseTypes'
import { media } from '../../../utils/style-utils'
import PrismicImage from '../../PrismicImage'

interface ICarouselBottomImageProps {
	image: IPrismicImage | undefined
}

const CarouselBottomImage: React.FC<ICarouselBottomImageProps> = ({
	image,
}) => {
	return (
		<>
			{image?.url && (
				<StyledPrismicImageWrap>
					<PrismicImage objectFit='contain' className='image' image={image} />
				</StyledPrismicImageWrap>
			)}
		</>
	)
}

export default CarouselBottomImage

const StyledPrismicImageWrap = styled.div`
	margin-top: 50px;
	width: 56px;
	height: 56px;

	${media.phone`
		margin-top: 0px;
	`}
`
