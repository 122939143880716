import { Translations } from '../locale/Translations'

export function convertRandomMathExpressionToString(
	expression: string | undefined,
	language: string | undefined
) {
	if (!expression) {
		return 'Expression is undefined'
	}

	if (language !== 'en' && language !== 'is') language = 'is'

	const digitWords: Record<string, string> =
		Translations[language].recaptcha.digits

	let convertedExpression = expression.replace(
		/\d/g,
		(digit) => digitWords[digit] || digit
	)

	if (language === 'en') {
		convertedExpression = convertedExpression.replace(
			Translations.is.recaptcha.exrpressionStart,
			Translations.en.recaptcha.exrpressionStart
		)
		convertedExpression = convertedExpression.replace(
			Translations.is.recaptcha.plus,
			Translations.en.recaptcha.plus
		)
		convertedExpression = convertedExpression.replace(
			Translations.is.recaptcha.minus,
			Translations.en.recaptcha.minus
		)
	}

	return convertedExpression
}

export function generateRandomMathExpression() {
	const operator = ['plús', 'mínus'][Math.floor(Math.random() * 2)]
	const num1 = Math.floor(Math.random() * 10) // Random number between 0 and 9
	const num2 = Math.floor(
		Math.random() * (operator === 'mínus' ? num1 + 1 : 10)
	) // Random number between 0 and 9 or 0 and num1

	const expression = `Hvað er ${num1} ${operator} ${num2}`
	return expression
}

export function calculateCorrectAnswer(randomMathExpression: string) {
	const match = /Hvað er (\w+) (plús|mínus) (\w+)/.exec(randomMathExpression)

	if (match) {
		const [, num1, operator, num2] = match

		// Calculate the correct answer based on the operator
		let correctAnswer
		switch (operator) {
			case 'plús':
				correctAnswer = parseInt(num1, 10) + parseInt(num2, 10)
				break
			case 'mínus':
				correctAnswer = parseInt(num1, 10) - parseInt(num2, 10)
				break
			default:
				correctAnswer = 0
		}

		return correctAnswer.toString() // Convert the correct answer to a string
	}

	// Handle the case when the regular expression doesn't match
	return ''
}
