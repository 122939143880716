import React, { useState } from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import { HeroCarouselItem } from '../Slices/HeroCarouselSlice'
import { media } from '../../utils/style-utils'
import HeroBackground from '../Layout/HeroBackground'
import RichText from '../RichText/RichText'

interface HeroCarouselProps {
	items: HeroCarouselItem[]
}

const INTERVAL: number = 15000
const HeroCarousel: React.FC<HeroCarouselProps> = ({ items }) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	// * The StyledSwiper could be extracted to a reusable component,
	// * but as its implementation is quite finicky when overly generalized, I decided to keep it here
	return (
		<Container className='fullBleed'>
			<StyledSwiper
				modules={[Autoplay, Pagination]}
				autoplay={{ delay: INTERVAL }}
				pagination={true}
				onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
			>
				{map(items, (item, index) => {
					return (
						<SwiperSlide key={`slide-${index}`}>
							<AbsoluteText style={{ color: 'white' }}>
								<RichText
									content={items[currentSlide].text}
									serializerOptions={{
										enableFileIconAnchors: false,
										enableMediaLinks: false,
									}}
								/>
							</AbsoluteText>
							<Image
								position={item.position}
								backgroundUrl={item.image.url}
								alt='Styrkhafar'
							/>
						</SwiperSlide>
					)
				})}
			</StyledSwiper>
			<TextContainer>
				<TextWrapper>
					<RichText
						content={items[currentSlide].text}
						serializerOptions={{
							enableFileIconAnchors: false,
							enableMediaLinks: false,
						}}
					/>
				</TextWrapper>
				<DotsContainer>
					{map(items, (_, index) => (
						<Dot key={`dot-${index}`} isActive={currentSlide === index} />
					))}
				</DotsContainer>
			</TextContainer>
		</Container>
	)
}

const AbsoluteText = styled.div`
	display: none;
	position: absolute;
	bottom: 48px;
	/* margin-inline: 24px; */
	padding-inline: 24px;
	background: ${({ theme }) => theme.colors.grey900};
	width: 100%;

	${media.phone`
		display: unset;
	`};
`

const Dot = styled.div<{ isActive: boolean }>`
	border: 4px solid
		${({ isActive, theme }) =>
			isActive ? theme.colors.gold550 : theme.colors.grey200};
	border-radius: 50%;
	max-height: 8px;
`

const DotsContainer = styled.div`
	display: flex;
	align-items: flex-end;
	position: absolute;
	gap: 8px;
	bottom: 30px;
	${media.phone`
		height: 8px;
		justify-content: center;
		margin-bottom: 8px;
	`};
`

const Container = styled.section`
	min-height: 440px;
	display: flex;
	gap: 80px;
	width: 100%;
	overflow: hidden;
	background: ${({ theme }) => theme.colors.grey800};
`

const Image = styled(HeroBackground)<{ position?: string }>`
	height: 100%;
	width: 100%;
	object-fit: cover;
	background-position: ${({ position }) => position || 'center'};
`

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-basis: 37.5%;
	position: sticky;
	${media.phone`
		display: none;
	`};
`

const TextWrapper = styled.div`
	height: auto;
	bottom: 70px;
	position: relative;
	> h1 {
		font-family: AdobeCaslonPro;
	}
	> p {
		font-size: 1.5rem;
		font-family: AdobeCaslonPro;
		font-weight: 400;
		line-height: 2.5rem;
		max-width: 35ch;
	}

	${media.tablet`
		height: inherit;
	`}

	${media.phone`
		display: none;
	`};
	color: ${({ theme }) => theme.colors.grey100};
	max-width: 35ch;
`

const StyledSwiper = styled(Swiper)`
	margin-inline: unset;
	flex-basis: 60%;

	.swiper-pagination {
		display: none;
	}

	.swiper-pagination-bullet {
		background: ${({ theme }) => theme.colors.grey200};
		opacity: 1;
	}

	.swiper-pagination-bullet-active {
		background: ${({ theme }) => theme.colors.gold550};
	}

	${media.phone`
	flex-basis: 100%;
	.swiper-pagination {
		display: unset;
	}
	`};
`

export default HeroCarousel
