import * as React from "react"

import { KvikaSVGProps } from "./types"

const OrtusLogo = ({ height = 96, width = 96 }: KvikaSVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox='0 0 696 696'
    >
      <g>
        <path
          fill="#00779c"
          d="M 194.5,-0.5 C 195.833,-0.5 197.167,-0.5 198.5,-0.5C 314.005,115.172 429.338,231.005 544.5,347C 542.704,349.633 540.704,352.133 538.5,354.5C 424.452,467.881 310.785,581.548 197.5,695.5C 196.5,695.5 195.5,695.5 194.5,695.5C 194.631,694.761 194.464,694.094 194,693.5C 165,664.833 136,636.167 107,607.5C 101.954,602.258 97.1209,596.924 92.5,591.5C 95.8605,588.806 98.8605,585.806 101.5,582.5C 101.435,582.938 101.601,583.272 102,583.5C 180.873,504.96 259.373,426.127 337.5,347C 259.96,269.627 182.627,192.127 105.5,114.5C 103.49,111.438 101.157,109.438 98.5,108.5C 96.6957,106.505 94.6957,104.838 92.5,103.5C 92.369,102.761 92.5357,102.094 93,101.5C 127.035,67.632 160.868,33.632 194.5,-0.5 Z" />
      </g>
      <g>
        <path
          fill="#009dce"
          d="M 92.5,103.5 C 94.6957,104.838 96.6957,106.505 98.5,108.5C 100.273,111.06 102.273,113.393 104.5,115.5C 167.477,192.814 230.477,270.148 293.5,347.5C 229.477,425.852 165.477,504.186 101.5,582.5C 98.8605,585.806 95.8605,588.806 92.5,591.5C 92.5,428.833 92.5,266.167 92.5,103.5 Z" />
      </g>
      <g>
        <path
          fill="#0c4c60"
          d="M 105.5,114.5 C 104.833,114.5 104.5,114.833 104.5,115.5C 102.273,113.393 100.273,111.06 98.5,108.5C 101.157,109.438 103.49,111.438 105.5,114.5 Z" />
      </g>
      <g>
        <path
          fill="#152933"
          d="M 105.5,114.5 C 182.627,192.127 259.96,269.627 337.5,347C 259.373,426.127 180.873,504.96 102,583.5C 101.601,583.272 101.435,582.938 101.5,582.5C 165.477,504.186 229.477,425.852 293.5,347.5C 230.477,270.148 167.477,192.814 104.5,115.5C 104.5,114.833 104.833,114.5 105.5,114.5 Z" />
      </g>
    </svg>
  )
}
export default OrtusLogo
