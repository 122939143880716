import { sanitize } from 'isomorphic-dompurify'

export const getFileSize = (base64) => {
	if (base64 && typeof Buffer === 'function') {
		return Buffer.from(base64, 'base64').length
	} else {
		false
	}
}

export const validFiles = (files, mimeTypesAndExtensions) => {
	if (files) {
		let valid = true
		files.forEach((file) => {
			// Check file size, max is 25MB
			const fileSize = getFileSize(file.content)
			if (!(fileSize && fileSize <= 26214400)) {
				valid = false
			}

			// Check file name
			const secureNameRegex =
				/^[A-Za-zÁáÞþÐðÆæÖöÞþÝýÚúÉéÍíÆæEéÖöÞþÖöÚúÓó\xF3\u03010-9¨_\-\. ]{3,30}$/
			if (!secureNameRegex.test(file.file_name)) {
				valid = false
			}

			// Check file extension
			const extensions = Object.keys(mimeTypesAndExtensions)
			const fileExtension = file.file_name
				? file.file_name.split('.').pop()
				: undefined
			if (
				!(fileExtension && extensions.includes(fileExtension.toLowerCase()))
			) {
				valid = false
			}

			// Check file mime type
			if (
				!(
					fileExtension &&
					mimeTypesAndExtensions[fileExtension.toLowerCase()] ===
						file.content_type
				)
			) {
				valid = false
			}
		})
		return valid
	}

	return false
}

export const sanitizeFilenames = (files) => {
	return files.map((file) => {
		return {
			...file,
			file_name: file.file_name ? sanitize(file.file_name) : undefined,
		}
	})
}
