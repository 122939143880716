import React from 'react'
import styled from 'styled-components'

import Vimeo from '../../HtmlSerializer/Vimeo'
import { FullBleedGrid, Grid } from '../../Layout/Grid'
import { FullBleedSectionTitle } from '../../Section/SectionTitle'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { IBaseSlice } from '../../../types/prismic/baseTypes'

export interface IEmbeddedVideoSlice extends IBaseSlice {
	primary: {
		section_title?: string
		section_color: IPage.Section['section_color']
		url: string
	}
	slice_type: 'embedded_live_video'
}

interface IProps {
	sliceData: IEmbeddedVideoSlice
}

const EmbeddedVideoSlice = ({ sliceData }: IProps) => {
	if (!sliceData.primary.url) {
		return null
	}

	return (
		<VideoWrapper backgroundColor={sliceData.primary.section_color}>
			<InnerWrapper>
				<FullBleedSectionTitle title={sliceData.primary.section_title} />
				<Vimeo src={sliceData.primary.url} />
			</InnerWrapper>
		</VideoWrapper>
	)
}

export default EmbeddedVideoSlice

const VideoWrapper = styled(FullBleedGrid)``

const InnerWrapper = styled.div`
	grid-column: 2 / -2;
	padding-top: var(--spacer-base);
	padding-bottom: var(--spacer-xl);
`
