import React from 'react'

import { KvikaSVGProps } from './types'

const KvikaSymbol = ({
	height = '96',
	fill = '#A28352',
	width = '83',
}: KvikaSVGProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 83 96'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M55.2266 95.9998H68.9877V72.737L55.2266 63.8906V95.9998Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.813 82.3488H41.5741V54.7173L27.813 45.7617V82.3488Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.400391 68.5883H14.1615V36.5883L0.400391 27.5234V68.5883Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.1611 13.7617V36.5877L27.813 45.7617V13.7617H14.1611Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M69.2058 14.1992V73.1753L82.9669 82.4586V14.1992H69.2058Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M41.5747 0V54.7167L55.2266 63.8908V0H41.5747Z'
				fill={fill}
			/>
		</svg>
	)
}

export default KvikaSymbol
